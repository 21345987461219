import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { routes } from "core/constants";
import TargetV2 from "pages/TargetV2/TargetV2";

const NewHome = React.lazy(() =>
  import("pages/Home/Home-new").then(({ NewHome }) => ({
    default: NewHome,
  }))
);

const Shipment = React.lazy(() =>
  import("pages/Shipment").then(({ Shipment }) => ({
    default: Shipment,
  }))
);

const Simulate = React.lazy(() =>
  import("pages/Simulate").then(({ Simulate }) => ({
    default: Simulate,
  }))
);
const Dashboard = React.lazy(() =>
  import("pages/Dashboard").then(({ Dashboard }) => ({
    default: Dashboard,
  }))
);
// const Impact = React.lazy(() =>
//   import("pages/Impact").then(({ Impact }) => ({
//     default: Impact,
//   }))
// );
const Privacy = React.lazy(() =>
  import("pages/Privacy").then(({ Privacy }) => ({
    default: Privacy,
  }))
);

const AppsDetails = React.lazy(() =>
  import("pages/Apps/AppsDetails").then(({ AppsDetails }) => ({
    default: AppsDetails,
  }))
);

const AppsInfo = React.lazy(() =>
  import("pages/AppsInfo").then(({ AppsInfo }) => ({
    default: AppsInfo,
  }))
);

function Routes() {
  return (
    <React.Suspense fallback="Loading...">
      <Switch>
        <Route exact path="/">
          <Redirect to={routes.home} />
        </Route>
        <Route path={routes.home} exact component={NewHome} />
        <Route path={routes.targeting} exact component={TargetV2} />
        <Route path={routes.targetingDetails} exact component={TargetV2} />
        <Route path={routes.shipment} exact component={Shipment} />
        <Route path={routes.shipmentWithFiltersId} exact component={Shipment} />
        <Route path={routes.simulator} exact component={Simulate} />
        <Route
          path={routes.simulatorWithFiltersId}
          exact
          component={Simulate}
        />
        <Route path={routes.dashboard} exact component={Dashboard} />
        {/* <Route path={routes.impact} exact component={Impact} />
        <Route path={routes.impactWithFiltersId} exact component={Impact} /> */}
        <Route path={routes.privacy} exact component={Privacy} />
        <Route path={routes.appsDetails} exact component={AppsDetails} />
        <Route path={routes.targetPage} exact component={TargetV2} />
        <Route path={routes.appsInfo} exact component={AppsInfo} />
        <Route path={routes.targetAppDashboard} exact>
          <Dashboard></Dashboard>
        </Route>
        <Route render={() => <Redirect to={{ pathname: "/" }} />} />
      </Switch>
    </React.Suspense>
  );
}

export default Routes;
