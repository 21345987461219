import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box } from "@mui/material";
import SelectFilter from "./SelectFilter";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { DraggableStateSnapshot } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import DropDownSelectFilter from "./dropdownSelectFilter/DropDownSelectFilter";
import { SubGroupFilterItem } from "pages/TargetV2/types/targetV2.types";
import "./FilterRow.scss";

type MCCPFilterRowProps = {
  name: string;
  filtersSelected: Array<string>;
  filters: Array<string>;
  id: number;
  index: number;
  level: number;
  isMandatory?: boolean;
  snapchot: DraggableStateSnapshot;
  isResponsive: boolean;
  parentItem: SubGroupFilterItem;
};

const MCCPFilterRow: FC<MCCPFilterRowProps> = ({
  name,
  filtersSelected,
  filters,
  id,
  index,
  level,
  isMandatory,
  snapchot,
  isResponsive,
  parentItem,
}) => {
  const [isExpandFilter, setIsExpandFilter] = useState<boolean>(false);
  const { handleUpdateFilterSelected, isMissingMandatoryFields } =
    useTargetV2Context();
  const refDropdown = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (refDropdown.current && !refDropdown.current.contains(e.target)) {
        setIsExpandFilter(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [setIsExpandFilter]);

  const handleExpandFilter = useCallback(() => {
    if (
      name === "MCCP" &&
      parentItem &&
      parentItem.filterItemsChildren &&
      parentItem.filterItemsChildren.length > 3
    ) {
      if (
        parentItem.filterItemsChildren[0].filtersSelected &&
        parentItem.filterItemsChildren[0].filtersSelected.length > 0 &&
        parentItem.filterItemsChildren[1].filtersSelected &&
        parentItem.filterItemsChildren[1].filtersSelected.length > 0 &&
        parentItem.filterItemsChildren[2].filtersSelected &&
        parentItem.filterItemsChildren[2].filtersSelected.length > 0
      ) {
        setIsExpandFilter((state) => !state);
      }
    } else if (
      name === "MCCP" &&
      parentItem &&
      parentItem.filterItemsChildren &&
      parentItem.filterItemsChildren.length === 3
    ) {
      if (
        parentItem.filterItemsChildren[0].filtersSelected &&
        parentItem.filterItemsChildren[0].filtersSelected.length > 0 &&
        parentItem.filterItemsChildren[1].filtersSelected &&
        parentItem.filterItemsChildren[1].filtersSelected.length > 0
      ) {
        setIsExpandFilter((state) => !state);
      }
    } else if (
      name === "MCCP" &&
      parentItem &&
      parentItem.filterItemsChildren &&
      parentItem.filterItemsChildren.length === 2
    ) {
      if (
        parentItem.filterItemsChildren[0].filtersSelected &&
        parentItem.filterItemsChildren[0].filtersSelected.length > 0
      ) {
        setIsExpandFilter((state) => !state);
      }
    } else {
      setIsExpandFilter((state) => !state);
    }
  }, [parentItem,name]);

  const handleUpdateFilterClick = useCallback(
    (filter: string) => {
      let action: "Add" | "Remove" = "Add";

      if (filtersSelected.some((filterSelected) => filterSelected === filter)) {
        action = "Remove";
      }
      handleUpdateFilterSelected(id, index, action, filter, level, name);
    },
    [
      filtersSelected,
      handleUpdateFilterSelected,
      id,
      index,
      level,
      name,
    ]
  );

  const isDraggingStyle = useMemo(() => {
    if (snapchot.isDragging) {
      return {
        background: " #FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        border: "2px dashed #6DC1DF",
        borderRadius: " 8px",
        opacity: 1,
      };
    }
  }, [snapchot]);

  return (
    <Box
      className="filter-row-container"
      style={{ ...isDraggingStyle, marginRight: isMandatory ? "36px" : "" }}
    >
      {isMandatory && <Box className="mandatory-wrapper">{t("mandatory")}</Box>}
      <Box className="filter-list-wrapper">
        <Box display="flex" width="140px" gap="4px" alignItems="center">
          {!isMandatory && (
            <DragIndicatorIcon
              style={{
                color: snapchot.isDragging ? "#2D9DE2" : "#D9D9D9",
                height: "20px",
                width: "20px",
              }}
            />
          )}
          <Box
            className="filter-name"
            style={{ marginLeft: isMandatory ? "20px" : "" }}
          >
            {name} -
          </Box>
        </Box>
        <Box position="relative" width="100%" ref={refDropdown}>
          <SelectFilter
            handleExpandFilter={handleExpandFilter}
            filtersSelected={filtersSelected}
            isExpandFilter={isExpandFilter}
            isMandatory={isMandatory ?? false}
            handleUpdateFilterClick={handleUpdateFilterClick}
          />
          {isMandatory &&
            filtersSelected.length < 1 &&
            isMissingMandatoryFields && (
              <Box className="mandatory-missing">
                The {name} need to be selected
              </Box>
            )}
          {isExpandFilter && (
            <DropDownSelectFilter
              isMulti={!isMandatory}
              handleUpdateFilter={handleUpdateFilterClick}
              filters={filters}
              filtersSelected={filtersSelected}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MCCPFilterRow;
