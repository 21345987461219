import { Routes } from "models/routes";

export const DATE_FORMAT_CLIENT = "dd MMM, yyyy";
export const DATE_TIME_FORMAT_API = "yyyy-MM-dd hh:mm:ss";
export const DATE_TIME_FORMAT_UPLOAD = "yyyy-MM-dd_hh:mm:ss";
export const DATE_FORMAT_API = "yyyy-MM-dd";
export const DATE_FORMAT_UPDATED = "dd-MM-yyyy";
export const RECORDS_PER_PAGE = 10;

// Routes
/** The properties of routes should match with the below files
 * 'configureBreadcrumbs' in breadcrumbsHelper.ts
 * 'headerMapping' in Pageheader.tsx */
export const routes: Routes = {
  newHome: "/newHome",
  home: "/home",
  suggestions: "/suggestions",
  suggestionsWithPageNum: "/suggestions/:page/:id",
  targeting: "/targeting",
  targetingDetails: "/targeting/:id",
  shipment: "/shipment",
  shipmentWithFiltersId: "/shipment/:id",
  targetFilter: "/targeting/new/:openTab",
  targetDetails: "/targeting/new/:openTab/:targetId",
  viewTargeting: "/targeting/view/:id",
  simulator: "/simulate",
  simulatorWithFiltersId: "/simulate/:id",
  targetingScreens: "/targeting/:fromScreen",
  sampleComponents: "/samplecomponents",
  dashboard: "/dashboard",
  impact: "/impact",
  impactWithFiltersId: "/impact/:id",
  privacy: "/privacy",
  apps: "/apps",
  appsDetails: "/apps/:id",
  targetPage: "/targetPage/",
  appsInfo: "/appsInfo/:id",
  targetAppDashboard: "/targetAppDashboard/",
};
//Storage Keys
export const storageKeys = {
  CDP_TOKEN_ACCESS: "cdp-token-access",
  KEYCLOAK_TOKEN_ACCESS: "client-token-access",
  TARGET_CSV: "target-csv",
  USER_MAIL: "user-email",
  ASYNC_CALL_SET: "async-call-set",
};
//File Upload
export const fileUploadKeys = {
  TARGETS_DETAILS: "targets_details",
};

export const asyncCAllMessages = {
  TARGETS_TOTAL_COUNT: "Target totals details are available",
  TARGETS_SIMULATION: "Simulation details are available",
  TARGETS_SHIPMENT_DETAILS_SFMC: "Shipment details are sent",
  TARGETS_SHIPMENT_DETAILS_LOCAL: "Download shipment info here",
  TARGETS_SIMULATION_DETAILS_LOCAL: "Download simulate info",
  SUGGESTION_TARGET_EXPORT_DETAIL: "Suggestion target info",
  SUGGESTION_SHIPMENT_EXPORT_DETAIL: "Suggestion shipment info",
  TARGETS_IMPACT: "Impact details are available",
  TARGETS_IMPACT_TOUCHPOINT: "Touchpoint details are available",
  TARGETS_HOME_SFMC: "File sent to SFMC Successfully",
  TARGETS_TOTAL_COUNT_ERR: "Target totals details are unavailable",
  TARGETS_SIMULATION_ERR: "Simulation details are unavailable",
  TARGETS_SHIPMENT_DETAILS_SFMC_ERR: "Shipment details are not sent",
  TARGETS_SHIPMENT_DETAILS_LOCAL_ERR: "Download shipment info failed",
  TARGETS_SIMULATION_DETAILS_LOCA_ERRL: "Download simulate info failed",
  SUGGESTION_TARGET_EXPORT_DETAIL_ERR: "Suggestion target info failed",
  SUGGESTION_SHIPMENT_EXPORT_DETAIL_ERR: "Suggestion shipment info failed",
  TARGETS_IMPACT_ERR: "Impact details are unavailable",
  TARGETS_IMPACT_TOUCHPOINT_ERR: "Touchpoint details are unavailable",
  TARGETS_HOME_SFMC_ERR: "File uploaded to SFMC failed",
  TARGETS_SIMULATION_DETAILS_LOCAL_ERR: "Simulation details are unavailable",
  TARGETS_CSV_EXPORT_LOCAL_ERR: "Target CSV export is failed",
};
