import { Box, Grid } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/shared/Button";
import { Popup } from "components/shared/Popup";
import { useTargetV2Context } from "../../context/targetV2.context";
import { usePapaParse } from "react-papaparse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export function Preview({ hcps }: { hcps: any }) {
  const { t } = useTranslation();
  const [openPopup, setOpenPopup] = useState(false);
  const [formattedURL, setFormattedURL] = useState("");
  const [parsedData, setParsedData] = useState<any>();
  const { targetListPreviewSignedURL } = useTargetV2Context();
  const { readRemoteFile } = usePapaParse();
  const handleReadRemoteFile = useCallback(
    (url: any) => {
      readRemoteFile(url, {
        delimiter: ";",
        download: true,
        complete: (results) => {
          if (results && results.data) {
            console.log("parse data", results.data);
            setParsedData(results.data);
          }
        },
      });
    },
    [readRemoteFile]
  );

  useEffect(() => {
    if (
      typeof targetListPreviewSignedURL == "string" &&
      targetListPreviewSignedURL.includes("https://")
    ) {
      setFormattedURL(targetListPreviewSignedURL);
      handleReadRemoteFile(targetListPreviewSignedURL);
    }
  }, [targetListPreviewSignedURL, handleReadRemoteFile]);

  const openListPopup = () => {
    setOpenPopup(true);
  };
  const closeListPopup = () => {
    setOpenPopup(false);
  };
  var headTable: any;
  const getTableHeader = () => {
    if (parsedData && Array.isArray(parsedData) && parsedData.length > 0) {
      const headerArray = parsedData[0];
      if (
        headerArray &&
        Array.isArray(headerArray) &&
        headerArray.length > 24
      ) {
        const toAssignHead = headerArray.slice(0, 24);
        headTable = [...toAssignHead];
      } else if (
        headerArray &&
        Array.isArray(headerArray) &&
        headerArray.length > 0
      ) {
        headTable = [...headerArray];
      }
    }
    const rows =
      headTable && Array.isArray(headTable) && headTable.length > 0
        ? headTable.map((item: any, index: number) => {
            return <TableCell key={index}>{item}</TableCell>;
          })
        : null;
    return rows;
  };
  const getTableBody = () => {
    var bodyTable;
    if (parsedData && Array.isArray(parsedData) && parsedData.length > 1) {
      const firstRow = parsedData[1];
      const indexOfCRM = (element: string) => element === "BayerCRM_IDValue";
      const posBayerCRM_IDValue = headTable
        ? headTable.findIndex(indexOfCRM)
        : 0;
      if (
        firstRow &&
        Array.isArray(firstRow) &&
        firstRow.length > posBayerCRM_IDValue &&
        firstRow[posBayerCRM_IDValue] === "123456789"
      ) {
        if (parsedData.length > 12) {
          const bodyRowSelected = parsedData.slice(2, 12);
          const bodyColumnSelected = bodyRowSelected.map((x) => x.slice(0, 24));
          bodyTable = bodyColumnSelected;
        } else if (parsedData.length < 13) {
          const bodyRowSelected = parsedData.slice(2);
          const bodyColumnSelected = bodyRowSelected.map((x) => x.slice(0, 24));
          bodyTable = bodyColumnSelected;
        }
      } else {
        if (parsedData.length > 11) {
          const bodyRowSelected = parsedData.slice(1, 11);
          const bodyColumnSelected = bodyRowSelected.map((x) => x.slice(0, 24));
          bodyTable = bodyColumnSelected;
        } else if (parsedData.length < 12) {
          const bodyRowSelected = parsedData.slice(1);
          const bodyColumnSelected = bodyRowSelected.map((x) => x.slice(0, 24));
          bodyTable = bodyColumnSelected;
        }
      }
    }
    const rows =
      bodyTable && Array.isArray(bodyTable) && bodyTable.length > 0
        ? bodyTable.map((item: any, index: number) => {
            return (
              <TableRow key={index}>
                {item.map((data: any, index: number) => (
                  <TableCell key={index}>{data}</TableCell>
                ))}
              </TableRow>
            );
          })
        : null;
    return rows;
  };

  return (
    <>
      <Box position="fixed" right="0px" top="30%">
        <Box className="preview-wrapper-button" onClick={openListPopup}>
          <Box className="preview-text">{t("preview")}</Box>
        </Box>
      </Box>
      <Popup
        open={openPopup}
        title={t("preview_target")}
        handleClose={closeListPopup}
        parentClass="preview-table-container"
      >
        {formattedURL && hcps !== 0 && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>{getTableHeader()}</TableRow>
              </TableHead>
              <TableBody>{getTableBody()}</TableBody>
            </Table>
          </TableContainer>
        )}
        {!formattedURL && (
          <Box className="no-data-preview">{t("no_data_available")}</Box>
        )}
        {hcps === 0 && formattedURL && (
          <Box className="no-data-preview">
            {t("no_data_available_filters")}
          </Box>
        )}
        <Grid
          className={`popup-footer-container ${
            !formattedURL || hcps === 0 ? "no-items" : ""
          }`}
        >
          {formattedURL && hcps !== 0 && (
            <Box
              display="flex"
              justifyContent="flex-end"
              gap="25px"
              alignItems="center"
            >
              <Box className="download-tag">{t("download_preview_info")}</Box>
              <a href={formattedURL}>
                <Button type="small" variant="secondary">
                  {t("download")}
                </Button>
              </a>
            </Box>
          )}
        </Grid>
      </Popup>
    </>
  );
}

export default Preview;
