import Keycloak from "keycloak-js";
import { EventEmitter } from "events";
import { getSessionItem, setSessionItem } from "core/utils/helper";

class Authenticator {
  keycloak = null;
  events = null;
  initialized = false;

  constructor() {
    this.events = new EventEmitter();
  }

  init({ clientId, silentSSOFileUrl, realm, keycloakUrl }) {
    this.keycloak = Keycloak({
      realm: realm,
      url: keycloakUrl,
      clientId: clientId,
    });

    let keycloak = this.keycloak;
    let authenticator = this;

    // Allow initialization only once
    if (this.initialized) {
      return;
    }

    this.initialized = true;

    console.debug("Auth: Init");

    // Setup Keycloak hooks
    keycloak.onAuthRefreshSuccess = function () {
      console.debug("Access Token refreshed");

      // Store the new tokens
      authenticator.storeToken("access", keycloak.token);
      authenticator.storeToken("refresh", keycloak.refreshToken);

      // Emit refresh success event, so that subscribers can react
      authenticator.events.emit("auth-refresh-success");
    };

    keycloak.onAuthRefreshError = function () {
      console.error(
        "Auth: Failed to refresh OAuth Access token - restarting login process"
      );
      keycloak.login();

      // Emit refresh error event, so that subscribers can react
      authenticator.events.emit("auth-refresh-error");
    };

    keycloak.onAuthSuccess = function () {
      if (!keycloak.isTokenExpired(5)) {
        // Store the new tokens
        authenticator.storeToken("access", keycloak.token);
        authenticator.storeToken("refresh", keycloak.refreshToken);

        // Emit refresh error event, so that subscribers can react
        authenticator.events.emit("auth-success");
      } else {
        console.debug(
          "Auth: Success, but login redirect, because tokens are expired"
        );
        keycloak.login();
      }
    };

    keycloak.onTokenExpired = function () {
      console.debug("Auth: TokenExpired");
      keycloak.updateToken();

      // Emit refresh error event, so that subscribers can react
      authenticator.events.emit("auth-token-expired");
    };

    keycloak.onReady = function (authenticated) {
      if (!authenticated) {
        console.info(
          "Auth: Log in redirect, because user not yet authenticated"
        );
        keycloak.login();
      }
    };

    // initialize the authentication process with Keycloak
    keycloak
      .init({
        onLoad: "check-sso",
        silentCheckSsoRedirectUri: silentSSOFileUrl,
        promiseType: "native",
        token: authenticator.getStoredToken("access"),
        refreshToken: authenticator.getStoredToken("refresh"),
        timeSkew: 5,
      })
      .catch(function (e) {
        console.error("Auth: Failed to initialize: " + e);

        alert(
          "Auth: Failed to initialize Authentication. Please reload - if this does not disappear, reach out to the WSF support."
        );

        // Emit refresh error event, so that subscribers can react
        authenticator.events.emit("auth-init-failed", e);
      });
  }

  isAuthenticated() {
    if (this.keycloak) {
      if (!this.keycloak.isTokenExpired(5)) {
        return this.keycloak.authenticated;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  storeToken(tokenName, token) {
    setSessionItem("client-token-" + tokenName, token);
  }

  getStoredToken(tokenName) {
    return getSessionItem("client-token-" + tokenName);
  }
}

export default Authenticator;
