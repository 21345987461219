import { Box, Grid } from "@mui/material";
import {
  SubGroupFilterItem,
  OperatorType,
} from "pages/TargetV2/types/targetV2.types";
import React, { FC, useState, useCallback } from "react";
import "../pastInteraction/PastInteractionSubGroupContainer.scss";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";
import "../SubGroupFilterCustom.scss";
import OperatorLine from "../../components/operatorLine/OperatorLine";
import { useTranslation } from "react-i18next";
import SelectOperatorDropDown from "../../components/selectOperatorDropDown/SelectOperatorDropDown";
import SimpleDateRange from "../../filterRow/dateRange/SimpleDateRange";
import SurveyNameContainer from "./SurveyNameContainer";

type SurveyNewContainerProps = {
  parentFilterItem: SubGroupFilterItem;
  subGroupFilterItem: SubGroupFilterItem;
  index: number;
  level: number;
  snapchot: any;
};

const SurveyNewContainer: FC<SurveyNewContainerProps> = ({
  parentFilterItem,
  subGroupFilterItem,
  index,
  level,
  snapchot,
}) => {
  const { t } = useTranslation();
  const {
    handleDeleteSubGroupFilterItem,
    arrayOfOperators,
    handleUpdateOperator,
    handleUpdateOperatorBetweenFiltersSelected,
  } = useTargetV2Context();

  const [expandedOperatorForAnswers, setExpandedOperatorForAnswers] = useState<
    number | null
  >(null);

  const [isExpandFilterOfOperator, setIsExpandFilterOfOperator] =
    useState<boolean>(false);

  const handleUpdateOperatorForSurveyNameClick = useCallback(
    (operator: string, i?: number) => {
      const operatorToAdd: OperatorType = arrayOfOperators.filter(
        (e) => e === operator
      )[0];
      if (i !== undefined) {
        handleUpdateOperatorBetweenFiltersSelected(
          subGroupFilterItem.subgGroupFilterItemsChildren[0].subGroupId,
          i,
          operatorToAdd
        );
        setExpandedOperatorForAnswers(null);
      }
    },
    [
      subGroupFilterItem,
      handleUpdateOperatorBetweenFiltersSelected,
      arrayOfOperators,
    ]
  );

  return (
    <>
      <Grid className="sub-group-custom-wrapper" key={index}>
        <Box display="flex" justifyContent="space-between" marginTop="8px">
          <Box display="flex" alignItems="center" gap="22px">
            <Box className="past-interaction-title-wrapper">
              <Box className="past-interaction-title">
                {subGroupFilterItem.name}
              </Box>
            </Box>
          </Box>
          <CancelIcon
            onClick={() =>
              handleDeleteSubGroupFilterItem(subGroupFilterItem.parentId, index)
            }
            cursor="pointer"
            className="cancel-icon"
          />
        </Box>

        <Box>
          {subGroupFilterItem.filterItemsChildren.length > 0 && (
            <SimpleDateRange
              key={subGroupFilterItem.filterItemsChildren[0].filterId}
              filterItem={subGroupFilterItem.filterItemsChildren[0]}
              id={subGroupFilterItem.subGroupId}
              index={index}
              groupType={subGroupFilterItem.groupType}
            />
          )}

          {subGroupFilterItem.subgGroupFilterItemsChildren?.length > 0 &&
            subGroupFilterItem.subgGroupFilterItemsChildren.map(
              (outerSubGroup, surveyIndexVal) => (
                <React.Fragment key={surveyIndexVal}>
                  <Box
                   // key={surveyIndexVal}
                    className="first-subgroup-container"
                  >
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      flexDirection="column"
                      justifyContent="space-between"
                      maxWidth="100%"
                     // key={surveyIndexVal}
                    >
                      {outerSubGroup.filterItemsChildren.length +
                        outerSubGroup.subgGroupFilterItemsChildren.length <
                        2 && (
                        <Box className="disable-help-text">
                          Add more filters to enable the operator
                        </Box>
                      )}
                      {/* Surveyname top group including oprator and cancel icon */}
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        marginBottom="10px"
                        key={surveyIndexVal}
                      >
                        <Box display="flex" alignItems="center" gap="22px">
                          <Box className="operator-select">
                            {t("select_your_operator_survey")}
                          </Box>
                          <SelectOperatorDropDown
                            operator={outerSubGroup.operator}
                            values={arrayOfOperators}
                            index={index}
                            handleUpdateOperatorClick={
                              handleUpdateOperatorForSurveyNameClick
                            }
                            expandedFilterOfOperator={isExpandFilterOfOperator}
                            setExpandedFilterOfOperator={
                              setIsExpandFilterOfOperator
                            }
                            disable={
                              outerSubGroup.filterItemsChildren.length +
                                outerSubGroup.subgGroupFilterItemsChildren
                                  .length <
                              2
                                ? true
                                : false
                            }
                          />
                        </Box>
                      </Box>
                      {/* end of Surveyname top group including oprator and cancel icon */}
                      {outerSubGroup.subgGroupFilterItemsChildren?.length > 0 &&
                        outerSubGroup.subgGroupFilterItemsChildren.map(
                          (subGroup, indexVal) => (
                            <React.Fragment key={indexVal}>
                              {/*  Operator between diff survey group*/}
                              {indexVal > 0 && (
                                <Box marginTop="24px" marginBottom="24px">
                                  <OperatorLine
                                    operator={outerSubGroup.operator}
                                    width="84%"
                                  />
                                </Box>
                              )}
                              {/*  end of Operator between diff survey group*/}

                              <SurveyNameContainer
                                subGroupFilterItem={subGroupFilterItem}
                                subGroup={subGroup}
                                indexVal={indexVal}
                                level={level}
                                snapchot={snapchot}
                              />
                            </React.Fragment>
                          )
                        )}
                    </Box>
                  </Box>
                </React.Fragment>
              )
            )}
        </Box>
      </Grid>
    </>
  );
};

export default SurveyNewContainer;
