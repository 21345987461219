import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Grid, Box, Typography } from "@mui/material";
import { MenuItem } from "models/menu";
import { getMenu } from "helpers/menuHelper";
import "./Menu.scss";
import { useTranslation } from "react-i18next";
import arrow_down from "../../../assets/icons/icon-arrow-down-black-filled.svg";
import { useRecoilValue } from "recoil";
import { userDetailsState } from "recoil/atoms";

export function Menu(props: any) {
  const defaultMenu = "home";
  let location = useLocation(); // updates on each route change
  // to highlight menu item with route change
  const { t } = useTranslation();
  const [pathName, setPathName] = useState<null | string>(null);
  const [showSubMenu, setShowSubMenu] = useState<boolean>(false);
  const [subPathName, setSubPathName] = useState<null | string>(null);

  const refSubMenu = useRef<any>();
  const userDetails = useRecoilValue(userDetailsState);

  useEffect(() => {
    // set pathName with each route change
    const path =
      location.pathname === "/"
        ? defaultMenu
        : location.pathname.split("/") &&
          location.pathname.split("/").length > 1
        ? location.pathname.split("/")[1]
        : defaultMenu;
  
        path === "shipment" ? setPathName("targeting"): setPathName(path);
  }, [location]);
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the target pop is open and the clicked target is not within the pop up,
      // then close the target pop
      if (
        showSubMenu &&
        refSubMenu.current &&
        !refSubMenu.current.contains(e.target)
      ) {
        setShowSubMenu(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showSubMenu]);
  const menu: MenuItem[] = getMenu();

  const handleMenuClick = () => {
    setShowSubMenu(!showSubMenu);
  };

  const handleSubMenuClick = (path: any, menuItem: any) => {
    if (menuItem.path !== path) {
      setSubPathName(path);
    }
  };

  const getSubMenu = (menuItem: any) => {
    return (
      <Box className="sub-menu" ref={refSubMenu}>
        <Grid container direction="column">
          {menuItem.subMenu.map((item: any) => {
            return (
              <>
                {userDetails.role === "Super User" && (
                  <Link
                    to={
                      item.external
                        ? {
                            pathname: `${item.path}`,
                            state: { page: `${item.page}` },
                          }
                        : {
                            pathname: `${item.inUse ? "/" + item.path : "#"}`,
                            state: { page: `${item.page}` },
                          }
                    }
                    className={item.inUse ? "" : "avoid-click"}
                    key={item.id}
                    target={item.external ? "_blank" : ""}
                    onClick={() => handleSubMenuClick(item.path, menuItem)}
                  >
                    <Grid
                      xs={12}
                      className={`sub-menu-option  ${
                        pathName === item.path ? "active-submenu-item" : " "
                      }`}
                      onClick={() => handleSubMenuClick(item.path, menuItem)}
                    >
                      {t(item.label)}
                    </Grid>
                  </Link>
                )}

                {userDetails.role === "Standard User" &&
                  item.label !== "simulate_menu_label" && (
                    <Link
                      to={
                        item.external
                          ? { pathname: `${item.path}` }
                          : `${item.inUse ? "/" + item.path : "#"}`
                      }
                      className={item.inUse ? "" : "avoid-click"}
                      key={item.id}
                      target={item.external ? "_blank" : ""}
                      onClick={() => handleSubMenuClick(item.path, menuItem)}
                    >
                      <Grid
                        xs={12}
                        className={`sub-menu-option  ${
                          pathName === item.path ? "active-submenu-item" : ""
                        }`}
                        onClick={() => handleSubMenuClick(item.path, menuItem)}
                      >
                        {t(item.label)}
                      </Grid>
                    </Link>
                  )}
              </>
            );
          })}
        </Grid>
      </Box>
    );
  };

  const getMenuItem = (menuItem: any) => {
    const checkActiveMenu = (menuItem: any) => {
      return (
        menuItem.hasSubmenu &&
        pathName &&
        menuItem.subMenu.filter((item: any) => pathName === item.path).length >
          0 
      );
    };
    return (
      <React.Fragment key={menuItem.id}>
        {" "}
        <Link
          to={
            menuItem.external
              ? { pathname: `${menuItem.path}` }
              : `${
                  menuItem.inUse
                    ? "/" +
                      (subPathName && menuItem.hasSubmenu
                        ? subPathName
                        : menuItem.path)
                    : "#"
                }`
          }
          className={` ${
            pathName === menuItem.path || checkActiveMenu(menuItem)
              ? "active-menu-link"
              : ""
          } `}
          key={menuItem.id}
          target={menuItem.external ? "_blank" : ""}
          onClick={menuItem.hasSubmenu === true ? handleMenuClick : undefined}
        >
          <Grid
            item
            xs="auto"
            alignContent="flex-start"
            className={`menu-item ${
              pathName === menuItem.path || checkActiveMenu(menuItem)
                ? "active-menu-item"
                : ""
            } `}
          >
            <Typography
              className={`menu-label ${
                pathName === menuItem.path || checkActiveMenu(menuItem)
                  ? "active-menu-label"
                  : ""
              }`}
            >
              {t(`${menuItem.label}`).toUpperCase()}{" "}
              {menuItem.hasSubmenu && <img src={arrow_down} alt="gray icon" />}
            </Typography>
            {menuItem.hasSubmenu && showSubMenu && getSubMenu(menuItem)}
          </Grid>
        </Link>{" "}
        <Grid
          item
          xs="auto"
          className={
            pathName === menuItem.path || checkActiveMenu(menuItem)
              ? "active-menu-right-pad"
              : ""
          }
        >
          {""}
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <Grid
      container
      direction="row"
      className="menu-section"
      alignContent="flex-end"
      justifyContent="center"
    >
      {menu.map((menuItem: MenuItem, index: number) => {
        return getMenuItem(menuItem);
      })}
    </Grid>
  );
}
