import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Tooltip } from "@mui/material";
import SelectFilter from "./SelectFilter";
import "./FilterRow.scss";
import delete_icon from "../../../../../../assets/icons/icon-delete.svg";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { DraggableStateSnapshot } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import DropDownSelectFilter from "./dropdownSelectFilter/DropDownSelectFilter";
import { makeStyles } from "@mui/styles";
import { ImageIcon } from "components/shared/ImageIcon";

type FilterRowProps = {
  name: string;
  filtersSelected: Array<string>;
  filters: Array<string>;
  id: number;
  index: number;
  level: number;
  isMandatory?: boolean;
  snapchot: DraggableStateSnapshot;
  isResponsive: boolean;
  isSurveyFilter?: boolean;
  isValidate?: boolean;
};
const useStyles = makeStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white",
  },
  tooltip: {
    backgroundColor: "white",
    boxShadow: "0px 3px 6px #00000029",
    width: "160px",
    color: "#808486",
    fontSize: "12px",
    fontFamily: "Open Sans Regular",
    padding: "8px",
  },
}));
const FilterRow: FC<FilterRowProps> = ({
  name,
  filtersSelected,
  filters,
  id,
  index,
  level,
  isMandatory,
  snapchot,
  isResponsive,
  isSurveyFilter,
  isValidate
}) => {
  const [isExpandFilter, setIsExpandFilter] = useState<boolean>(false);

  const {
    handleDeleteFilterRow,
    handleUpdateFilterSelected,
    handleUpdateSimpleFilterSelected,
    isMissingMandatoryFields,
  } = useTargetV2Context();

  const refDropdown = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (refDropdown.current && !refDropdown.current.contains(e.target)) {
        setIsExpandFilter(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [setIsExpandFilter]);

  const handleExpandFilter = useCallback(() => {
    setIsExpandFilter((state) => !state);
  }, []);

  const handleUpdateFilterClick = useCallback(
    (filter: string) => {
      let action: "Add" | "Remove" = "Add";

      if (filtersSelected.some((filterSelected) => filterSelected === filter)) {
        action = "Remove";
      }

      if (isMandatory) {
        handleUpdateSimpleFilterSelected(id, index, filter, isResponsive);
        setIsExpandFilter(false);
      } else {
        handleUpdateFilterSelected(id, index, action, filter, level);
      }
    },
    [
      filtersSelected,
      handleUpdateFilterSelected,
      id,
      index,
      level,
      handleUpdateSimpleFilterSelected,
      isMandatory,
      setIsExpandFilter,
      isResponsive,
    ]
  );

  const isDraggingStyle = useMemo(() => {
    if (snapchot.isDragging) {
      return {
        background: " #FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        border: "2px dashed #6DC1DF",
        borderRadius: " 8px",
        opacity: 1,
      };
    }
  }, [snapchot]);
  let classes = useStyles();

  return (
    <Box
      className="filter-row-container"
      style={{ ...isDraggingStyle, marginRight: isMandatory ? "36px" : "" }}
    >
      {isMandatory && name !=="Journey Id" && <Box className="mandatory-wrapper">{t("mandatory")}</Box>}
      <Box className="filter-list-wrapper">
        <Box
          display="flex"
          width={isSurveyFilter ? "40%" : "140px"}
          gap="4px"
          alignItems="center"
        >
          {!isMandatory && (
            <DragIndicatorIcon
              style={{
                color: snapchot.isDragging ? "#2D9DE2" : "#D9D9D9",
                height: "20px",
                width: "20px",
              }}
            />
          )}

          <Tooltip
            title={name && isSurveyFilter ? name : ""}
            placement="top"
            arrow
            classes={{
              arrow: classes.arrow,
              tooltip: classes.tooltip,
            }}
            PopperProps={{ keepMounted: true }}
          >
            <Box
              className={`filter-name ${
                name && isSurveyFilter ? "ellipse-container" : ""
              }`}
              style={{ marginLeft: isMandatory ? "20px" : "" }}
            >
              {isSurveyFilter && name.length > 50 ? (
                <>
                  {" "}
                  <span>{name.slice(0, 50)}</span>
                  <span className="survey_txt_clr"> ...?</span>{" "}
                </>
              ) : (
                `${name}-`
              )}
            </Box>
          </Tooltip>
        </Box>

        <Box position="relative" width="100%" ref={refDropdown}>          
          <SelectFilter
            handleExpandFilter={handleExpandFilter}
            filtersSelected={filtersSelected}
            isExpandFilter={isExpandFilter}
            isMandatory={isMandatory ?? false}
            handleUpdateFilterClick={handleUpdateFilterClick}
          />
          
          {isMandatory && name !=="Journey Id" &&
            filtersSelected.length < 1 &&
            isMissingMandatoryFields && (
              <Box className="mandatory-missing">
                The {name} need to be selected
              </Box>
            )}
             {isMandatory && name ==="Journey Id" && !isExpandFilter && (             
             <Box className="jourey-info">
              <ImageIcon classes="icon-group-info infrmtn-icon">
              &nbsp;             
              </ImageIcon>
              <Box>
              {t("journey-id-info")}
              </Box>
             </Box>
                  
             )
            }

          {isExpandFilter && (
            <DropDownSelectFilter
              isMulti={!isMandatory}
              handleUpdateFilter={handleUpdateFilterClick}
              filters={filters}
              filtersSelected={filtersSelected}
            />
          )}
        </Box>
        {!isMandatory && (
          <img
            src={delete_icon}
            alt="delete"
            onClick={() => handleDeleteFilterRow(id, index)}
          />
        )}
      </Box>
    </Box>
  );
};

export default FilterRow;
