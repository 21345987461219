import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../../StrictModeDroppable";
import "./SubGroupFilterItemsContainer.scss";
import { Box, Grid } from "@mui/material";
import {
  GroupType,
  OperatorType,
  SubGroupFilterItem,
} from "pages/TargetV2/types/targetV2.types";
import FilterRow from "./filterRow/FilterRow";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";
import SubGroupFilterCustomContainer from "./subGroupFilterCustom/SubGroupFilterCustomContainer";
import CancelIcon from "@mui/icons-material/Cancel";
import arrow_up_blue from "../../../../../../src/assets/icons/icon-arrow-up-blue-active.svg";
import SubGroupNotExpanded from "./subGroupNotExpanded/SubGroupNotExpanded";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import OperatorLine from "./components/operatorLine/OperatorLine";
import SelectOperatorDropDown from "./components/selectOperatorDropDown/SelectOperatorDropDown";
import EmptyBox from "./components/emptyBox/EmptyBox";
import NewGroupFooterBar from "./components/newGroupFooterBar/NewGroupFooterBar";
import SelectOperatorBetweenGroups from "./components/selectOperatorBetweenGroups/SelectOperatorBetweenGroups";
import DropDownHeaderMenu from "./components/dropDownHeaderMenu/DropDownHeaderMenu";
import MCCPNewContainer from "./subGroupFilterCustom/mccp/MCCPNewContainer";

type SubGroupFilterItemsContainerProps = {
  subGroupFilterItem: SubGroupFilterItem;
  level: number;
  index: number;
  groupType?: any;
};

const SubGroupFilterItemsContainer: FC<SubGroupFilterItemsContainerProps> = ({
  subGroupFilterItem,
  level,
  index,
  groupType,
}) => {
  const [
    isExpandedFilterOfOperatorBetweenGroups,
    setisExpandedFilterOfOperatorBetweenGroups,
  ] = useState<boolean>(false);

  const [
    expandedDropDownHeaderMenuNotExpanded,
    setExpandedDropDownHeaderMenuNotExpanded,
  ] = useState<number | null>();

  const [isExpandedFilterOfOperator, setIsExpandedFilterOfOperator] =
    useState<boolean>(false);

  const [isExpandedDropDownHeaderMenu, setIsExpandedDropDownHeaderMenu] =
    useState<boolean>(false);

  const {
    targetListData,
    handleDeleteSubGroupFilterItem,
    setGroupExpanded,
    groupExpanded,
    handleReplicatePreviousGroup,
    arrayOfOperators,
    handleUpdateOperator,
    handleUpdateOperatorBetweenGroups,
    handleUpdateTargetListData,
    isApplyingFilters,
    setIsMissingMandatoryFields,
  } = useTargetV2Context();

  const { t } = useTranslation();
  const groupStyle = useMemo(() => {
    if (level !== 0) {
      return {
        marginLeft:
          level > 1 ? (groupType && groupType === "SURVEY" ? "" : "32px") : "",
        paddingLeft: "12px",
        border: "1px solid #e6e6e6",
        paddingBottom: "34px",
      };
    }
  }, [level, groupType]);

  const updateGroupExpanded = useCallback(
    (id: number) => {
      if (subGroupFilterItem.subGroupId === groupExpanded) {
        setGroupExpanded(0);
      } else {
        setGroupExpanded(id);
      }
    },
    [subGroupFilterItem, groupExpanded, setGroupExpanded]
  );

  const handleUpdateOperatorClick = useCallback(
    (operator: string) => {
      const operatorToAdd: OperatorType = arrayOfOperators.filter(
        (e) => e === operator
      )[0];
      handleUpdateOperator(subGroupFilterItem.subGroupId, operatorToAdd);
      setIsExpandedFilterOfOperator(false);
    },
    [handleUpdateOperator, subGroupFilterItem, arrayOfOperators]
  );

  const handleUpdateOperatorBetweenGroupsClick = useCallback(
    (operator: string, indexOfGroups?: number) => {
      const operatorToAdd: OperatorType = arrayOfOperators.filter(
        (e) => e === operator
      )[0];

      if (indexOfGroups !== undefined) {
        handleUpdateOperatorBetweenGroups(
          subGroupFilterItem.subGroupId,
          indexOfGroups,
          operatorToAdd
        );

        setisExpandedFilterOfOperatorBetweenGroups(false);
      }
    },
    [handleUpdateOperatorBetweenGroups, subGroupFilterItem, arrayOfOperators]
  );

  const handleHeaderMenuOfGroupClick = useCallback(
    (____, indexOfGroups?: number, indexOfValue?: number) => {
      if (indexOfGroups !== undefined && indexOfValue !== undefined) {
        if (indexOfValue === 1) {
          handleReplicatePreviousGroup(
            targetListData[0].subgGroupFilterItemsChildren[indexOfGroups]
          );
          setExpandedDropDownHeaderMenuNotExpanded(null);
          setIsMissingMandatoryFields(false);
        } else if (indexOfValue === 0) {
          const copyOfState: SubGroupFilterItem[] = _.cloneDeep(targetListData);
          copyOfState[0].subgGroupFilterItemsChildren.splice(indexOfGroups, 1);
          handleUpdateTargetListData(copyOfState);
        }
      }
    },

    [
      handleReplicatePreviousGroup,
      targetListData,
      handleUpdateTargetListData,
      setIsMissingMandatoryFields,
    ]
  );

  useEffect(() => {
    if (targetListData[0].subgGroupFilterItemsChildren.length <= 0)
      setGroupExpanded(targetListData[0].id);
  }, [targetListData, setGroupExpanded]);

  return (
    <StrictModeDroppable
      droppableId={subGroupFilterItem.subGroupId.toString()}
      key={subGroupFilterItem.subGroupId.toString()}
      isDropDisabled={
        subGroupFilterItem.subGroupId === targetListData[0].subGroupId &&
        targetListData[0].subgGroupFilterItemsChildren.length > 0
      }
    >
      {(provided, snapchot) => (
        <Grid
          className="group-wrapper"
          style={{
            ...groupStyle,
            boxShadow: "inset 3px 3px 10px #00000022",
            border:
              level === 0 &&
              targetListData[0].subgGroupFilterItemsChildren.length >= 1
                ? ""
                : level === 1
                ? "2px dashed #6DC1DF"
                : snapchot.isDraggingOver
                ? "2px dashed #6DC1DF"
                : "1px solid #e6e6e6",
            minHeight:
              targetListData[0].subgGroupFilterItemsChildren.length <= 0
                ? "430px"
                : "",
            marginRight:
              level === 0
                ? "125px"
                : level > 1
                ? groupType && groupType === "SURVEY"
                  ? ""
                  : "50px"
                : "",
            borderRadius: "3px",
            position: "relative",
          }}
        >
          {level === 0 &&
            !subGroupFilterItem.subgGroupFilterItemsChildren.length && (
              <Box className="group-header">
                <Box
                  className="group-title select-filter-text"
                  paddingLeft="20px"
                >
                  {t("start_selecting_your_filter")}
                </Box>
              </Box>
            )}
          {subGroupFilterItem.isMCCPGroup && (
            <Box
              display="flex"
              alignItems="center"
              gap="7px"
              style={{ paddingTop: "22px" }}
            >
              <Box className="past-interaction-title-wrapper">
                <Box className="past-interaction-title">{t("mccp_plan")}</Box>
              </Box>
              <Box className="mccp_fill_up_info">{t("fill_up_mccp")}</Box>
            </Box>
          )}
          {level > 0 && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box className="group-header">
                {level === 1 && (
                  <Box className="group-title">
                    {subGroupFilterItem.name} {index + 1}
                    <Box
                      style={{
                        border: "1px dashed #C1C1C1",
                        opacity: 1,
                        marginLeft: "8px",
                        height: "15px",
                      }}
                    />
                  </Box>
                )}

                {subGroupFilterItem.isMCCPGroup ? (
                  <Box
                    className="operator-select"
                    style={{ paddingLeft: "24px" }}
                  >
                    {t("select_your_operator_mccp")}
                  </Box>
                ) : (
                  <Box className="operator-select">
                    {t("select_your_operator")}
                  </Box>
                )}

                {level !== 0 && (
                  <>
                    <SelectOperatorDropDown
                      operator={subGroupFilterItem.operator}
                      values={arrayOfOperators}
                      index={index}
                      handleUpdateOperatorClick={handleUpdateOperatorClick}
                      expandedFilterOfOperator={isExpandedFilterOfOperator}
                      setExpandedFilterOfOperator={
                        setIsExpandedFilterOfOperator
                      }
                      disable={
                        subGroupFilterItem.filterItemsChildren.length +
                          subGroupFilterItem.subgGroupFilterItemsChildren
                            .length <
                        2
                          ? true
                          : false
                      }
                    />
                    {subGroupFilterItem.filterItemsChildren.length +
                      subGroupFilterItem.subgGroupFilterItemsChildren.length <
                      2 && (
                      <Box className="disable-help-text">
                        Add more filters to enable the operator
                      </Box>
                    )}
                  </>
                )}
              </Box>
              {level > 1 && !(groupType && groupType === "SURVEY") && (
                <CancelIcon
                  onClick={() =>
                    handleDeleteSubGroupFilterItem(
                      subGroupFilterItem.parentId,
                      index
                    )
                  }
                  cursor="pointer"
                  style={{
                    width: "24px",
                    height: "24px",
                    color: "#c0cdd5",
                    opacity: 1,
                    paddingRight: "8px",
                    paddingTop: "6px",
                  }}
                />
              )}

              {level === 1 && (
                <Box
                  paddingRight="20px"
                  gap="8px"
                  display="flex"
                  alignItems="center"
                >
                  <img
                    src={arrow_up_blue}
                    alt="arrow up icon"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      updateGroupExpanded(subGroupFilterItem.subGroupId)
                    }
                  />
                  <DropDownHeaderMenu
                    handleHeaderMenuOfGroupClick={handleHeaderMenuOfGroupClick}
                    index={index}
                    setIsExpandDropDownMenu={setIsExpandedDropDownHeaderMenu}
                    isExpandDropDownMenu={isExpandedDropDownHeaderMenu}
                  />
                </Box>
              )}
            </Box>
          )}

          <div {...provided.droppableProps} ref={provided.innerRef}>
            {subGroupFilterItem && !subGroupFilterItem.isMCCPGroup && (
              <Box
                style={{
                  minHeight:
                    subGroupFilterItem.filterItemsChildren.length === 1 &&
                    targetListData[0].subgGroupFilterItemsChildren.length === 0
                      ? "400px"
                      : "40px",
                  paddingBottom:
                    subGroupFilterItem.filterItemsChildren.length === 1 &&
                    targetListData[0].subgGroupFilterItemsChildren.length === 0
                      ? "200px"
                      : "",
                }}
              >
                {subGroupFilterItem.filterItemsChildren.length > 0 && (
                  <div>
                    {subGroupFilterItem.filterItemsChildren.map(
                      (filterItemChildren, index) => (
                        <Draggable
                          draggableId={filterItemChildren.filterId.toString()}
                          index={index}
                          key={filterItemChildren.filterId.toString()}
                          isDragDisabled={filterItemChildren.isMandatory}
                        >
                          {(provided, snapchot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Box position="relative">
                                <FilterRow
                                  index={index}
                                  name={filterItemChildren.name}
                                  filters={filterItemChildren.filters}
                                  filtersSelected={
                                    filterItemChildren.filtersSelected
                                  }
                                  id={subGroupFilterItem.subGroupId}
                                  level={level}
                                  isMandatory={filterItemChildren.isMandatory}
                                  snapchot={snapchot}
                                  isResponsive={filterItemChildren.isResponsive}
                                  isSurveyFilter={
                                    groupType && groupType === "SURVEY"
                                      ? true
                                      : false
                                  }
                                />

                                {isApplyingFilters &&
                                  filterItemChildren.countHCPS !== null && (
                                    <Box
                                      style={{
                                        position: "absolute",
                                        right:
                                          level <= 1
                                            ? -108
                                            : groupType === "MCCP" && level <= 2
                                            ? -212
                                            : groupType === "MCCP" && level > 2
                                            ? -260
                                            : groupType === "SURVEY" &&
                                              level <= 3
                                            ? -172
                                            : groupType === "SURVEY" &&
                                              level > 3
                                            ? -224
                                            : -108 - (level - 1) * 52,
                                        bottom: 26,
                                      }}
                                    >
                                      <Box className="count-hcps-wrapper">
                                        <Box className="count-hcps-number">
                                          {filterItemChildren.countHCPS} HCPS
                                        </Box>
                                      </Box>
                                    </Box>
                                  )}
                              </Box>

                              {index <
                                subGroupFilterItem.filterItemsChildren.length -
                                  1 && (
                                <Box marginLeft="34px">
                                  <OperatorLine
                                    operator={subGroupFilterItem.operator}
                                    width="84%"
                                  />
                                </Box>
                              )}
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                  </div>
                )}
              </Box>
            )}
            {provided.placeholder}
          </div>
          {subGroupFilterItem.filterItemsChildren.length > 0 &&
            subGroupFilterItem.subgGroupFilterItemsChildren.length > 0 && (
              <Box marginLeft="34px">
                <OperatorLine
                  operator={subGroupFilterItem.operator}
                  width="84%"
                />
              </Box>
            )}

          {/* If targetListData is empty (1 filterItem) add emptyBox */}
          {level === 0 &&
            subGroupFilterItem.filterItemsChildren.length === 1 &&
            subGroupFilterItem.subgGroupFilterItemsChildren.length === 0 && (
              <EmptyBox />
            )}

          {subGroupFilterItem.subgGroupFilterItemsChildren.length > 0 && (
            <div>
              {subGroupFilterItem.subgGroupFilterItemsChildren.map(
                (subGroupFilterItemChildren, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && level !== 0 && (
                      <Box marginLeft="34px">
                        <OperatorLine
                          operator={subGroupFilterItem.operator} //oeatior line between two mccp
                          width="84%"
                        />
                      </Box>
                    )}
                    <div key={index} style={{ position: "relative" }}>
                      {subGroupFilterItemChildren.groupType !==
                      GroupType.CLASSIC ? (
                        subGroupFilterItemChildren.groupType ===
                        GroupType.MCCP ? (
                          <MCCPNewContainer
                            parentFilterItem={subGroupFilterItem}
                            subGroupFilterItem={subGroupFilterItemChildren}
                            index={index}
                            level={level}
                            snapchot={snapchot}
                            groupType={subGroupFilterItemChildren.groupType}
                          />
                        ) : (
                          <SubGroupFilterCustomContainer
                            parentFilterItem={subGroupFilterItem}
                            subGroupFilterItem={subGroupFilterItemChildren}
                            level={level + 1}
                            index={index}
                            groupType={subGroupFilterItemChildren.groupType}
                            snapchot={snapchot}
                          />
                        )
                      ) : subGroupFilterItemChildren.subGroupId !==
                          groupExpanded && level < 1 ? (
                        <SubGroupNotExpanded
                          subgGroupFilterItem={subGroupFilterItemChildren}
                          index={index}
                          handleHeaderMenuOfGroupClick={
                            handleHeaderMenuOfGroupClick
                          }
                          expandedDropDownHeaderMenu={
                            expandedDropDownHeaderMenuNotExpanded
                          }
                          setExpandedDropDownHeaderMenu={
                            setExpandedDropDownHeaderMenuNotExpanded
                          }
                        />
                      ) : (
                        <SubGroupFilterItemsContainer
                          subGroupFilterItem={subGroupFilterItemChildren}
                          level={level + 1}
                          index={index}
                        />
                      )}

                      {level < 1 &&
                        subGroupFilterItemChildren.subGroupId ===
                          groupExpanded && (
                          <SelectOperatorBetweenGroups
                            index={index}
                            subGroupFilterItem={subGroupFilterItem}
                            subGroupFilterItemChildren={
                              subGroupFilterItemChildren
                            }
                            isExpandedFilterOfOperatorBetweenGroups={
                              isExpandedFilterOfOperatorBetweenGroups
                            }
                            setIsExpandedFilterOfOperatorBetweenGroups={
                              setisExpandedFilterOfOperatorBetweenGroups
                            }
                            handleUpdateOperatorBetweenGroupsClick={
                              handleUpdateOperatorBetweenGroupsClick
                            }
                          />
                        )}
                      {index ===
                        subGroupFilterItem.subgGroupFilterItemsChildren.length -
                          1 &&
                        level <= 0 &&
                        subGroupFilterItem.subgGroupFilterItemsChildren.length <
                          6 && (
                          <NewGroupFooterBar
                            index={index}
                            subGroupFilterItemChildren={
                              subGroupFilterItemChildren
                            }
                          />
                        )}
                    </div>
                  </React.Fragment>
                )
              )}
            </div>
          )}
        </Grid>
      )}
    </StrictModeDroppable>
  );
};

export default SubGroupFilterItemsContainer;
