import { Box, Grid } from "@mui/material";
import { SubGroupFilterItem } from "pages/TargetV2/types/targetV2.types";
import React, { FC } from "react";
import "./PastInteractionSubGroupContainer.scss";
import SimpleFilterRow from "../../filterRow/SimpleFilterRow";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";
import SimpleDateRange from "../../filterRow/dateRange/SimpleDateRange";
import { useTranslation } from "react-i18next";
import "../SubGroupFilterCustom.scss";

type PastInteractionSubGroupContainerProps = {
  subGroupFilterItem: SubGroupFilterItem;
  index: number;
};

const PastInteractionSubGroupContainer: FC<
  PastInteractionSubGroupContainerProps
> = ({ subGroupFilterItem, index }) => {
  const { handleDeleteSubGroupFilterItem, isApplyingFilters } =
    useTargetV2Context();

  const { t } = useTranslation();
  const clearCount = () => {
    if (subGroupFilterItem?.subgGroupFilterItemsChildren?.length > 0)
      subGroupFilterItem.subgGroupFilterItemsChildren.forEach(
        (subGroup) => (subGroup.countHCPS = null)
      );
  };
  return (
    <Grid className="sub-group-custom-wrapper">
      <Box display="flex" justifyContent="space-between" marginTop="8px">
        <Box className="past-interaction-title-wrapper">
          <Box className="past-interaction-title">
            {subGroupFilterItem.name}
          </Box>
        </Box>
        <CancelIcon
          onClick={() =>
            handleDeleteSubGroupFilterItem(subGroupFilterItem.parentId, index)
          }
          cursor="pointer"
          className="cancel-icon"
        />
      </Box>

      <Box>
        {subGroupFilterItem.filterItemsChildren.length > 0 && (
          <SimpleDateRange
            key={subGroupFilterItem.filterItemsChildren[0].filterId}
            filterItem={subGroupFilterItem.filterItemsChildren[0]}
            id={subGroupFilterItem.subGroupId}
            index={index}
            onChange={clearCount}
          />
        )}

        {subGroupFilterItem.subgGroupFilterItemsChildren?.length > 0 &&
          subGroupFilterItem.subgGroupFilterItemsChildren.map((subGroup) => (
            <Box key={subGroup.subGroupId} className="first-subgroup-container">
              {subGroup.filterItemsChildren.find((e) => e.isMandatory) && (
                <Box className="past-interaction-mandatory">
                  {t("fillup_mandatory_fields")}
                </Box>
              )}
              <Box
                display="flex"
                flexWrap="wrap"
                marginTop="22px"
                justifyContent="space-between"
                maxWidth="95%"
              >
                {subGroup.filterItemsChildren.map((filterItem, index) => (
                  <SimpleFilterRow
                    key={filterItem.filterId}
                    filterItem={filterItem}
                    id={subGroup.subGroupId}
                    index={index}
                    width="100%"
                    halfWidth={true}
                  />
                ))}
              </Box>
              {isApplyingFilters && subGroup?.countHCPS !== null && (
                <Box
                  style={{
                    position: "absolute",
                    right: -108,
                    top: 160,
                  }}
                >
                  <Box className="count-hcps-wrapper">
                    <Box className="count-hcps-number">
                      {subGroup.countHCPS} HCPS
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          ))}
      </Box>
    </Grid>
  );
};

export default PastInteractionSubGroupContainer;
