import React, { Component } from "react";
import { Grid } from "@mui/material";
import { Menu } from "components/core/Menu";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

class ErrorBoundary extends Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: false };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Grid container justifyContent="center" className="layout-container">
          <Grid item md={1} className="menu-container">
            <Menu />
          </Grid>
          <Grid item md={6} xs={6} className="error-message">
            <ErrorOutlineIcon className="error-icon" />
            <h1 className="message-text">Oops!</h1>
            <h3 className="message-text">Something went wrong.</h3>
          </Grid>
        </Grid>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
