import { useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { Button } from "components/shared/Button";
import { useTranslation } from "react-i18next";
import { useCSVReader, formatFileSize } from "react-papaparse";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { ImageIcon } from "../ImageIcon";
import { userUploadedCSVState, userOperationState } from "recoil/atoms";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";
import "./CustomFileDropzone.scss";

export function CustomFileDropzone(props: any) {
  const { CSVReader } = useCSVReader();
  const { t } = useTranslation();
  const { fetchCustomTemplate } = useTargetV2Context();
  const [isUploaded, setIsUploaded] = useState(false);
  const setUserUploadedCSVState = useSetRecoilState(userUploadedCSVState);
  const userOperation = useRecoilValue(userOperationState);

  const onSubmit = (event: any) => {
    event.preventDefault();
    if (props.onValidateFile) {
      props.onValidateFile();
    }
  };

  const downloadCsvTemplate = () => {
    fetchCustomTemplate();
  };

  return (
    <Grid container className="custom-dropzone-wrapper">
      {userOperation === "Remove" && (
        <Box className="alert-box" display="flex">
          <Box className="alert-container">
            <ImageIcon classes="img-alert-no-fill "></ImageIcon>
          </Box>
          <p>{t("remove_alert")}</p>
        </Box>
      )}

      <Box
        className="map-download"
        display="flex"
        onClick={downloadCsvTemplate}
      >
        <ImageIcon classes="icon-downloading-small add-image"></ImageIcon>
        <p>{t("download_csv_template")}</p>
      </Box>
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        justifyContent="center"
        className="dropzone-container"
      >
        <CSVReader
          onUploadAccepted={(results: any) => {
            setIsUploaded(true);
            if (results.data && results.data.length > 1) {
              let userData = [...results.data];
              let lastElement = userData.pop();
              //sometimes the last row being empty is read as an input and if such a data is there, remove it
              if (
                lastElement &&
                lastElement.length == 1 &&
                lastElement[0] == ""
              ) {
                setUserUploadedCSVState(userData);
              } else {
                setUserUploadedCSVState(results.data);
              }
            }
          }}
        >
          {({ getRootProps, acceptedFile, getRemoveFileProps }: any) => (
            <>
              <div {...getRootProps()} style={Object.assign({})}>
                {acceptedFile ? (
                  <>
                    <div className="dropzone disabled">
                      <Grid item>
                        <Box className="icon-drag-and-drop">
                          <ImageIcon classes="icon-drag-and-drop-blue"></ImageIcon>
                        </Box>
                      </Grid>
                      <Grid>
                        <Typography>{t("drag_and_drop_file")}</Typography>
                      </Grid>
                    </div>
                    <Grid
                      item
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      className="listing-wrapper"
                    >
                      <div className="file-listing">
                        <Grid container key={`${acceptedFile.name}`}>
                          <Grid item>
                            <Box className="icon-csv-file">
                              <ImageIcon classes="img-csv-file"></ImageIcon>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box>
                              <p className="file-title">
                                {acceptedFile.name} {t("file_ready_to_upload")}
                              </p>
                            </Box>
                            <Box>
                              <p className="file-size">
                                {formatFileSize(acceptedFile.size)}
                              </p>
                            </Box>
                          </Grid>
                          <Grid item className="icon-delete-wrapper">
                            <Box
                              className="delete-icon"
                              {...getRemoveFileProps()}
                            >
                              <div
                                className="icon-wrapper"
                                onClick={(e) => {
                                  setIsUploaded(false);
                                  setUserUploadedCSVState([]);
                                }}
                              >
                                <ImageIcon classes="icon-delete"></ImageIcon>
                              </div>
                            </Box>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </>
                ) : (
                  <div className="dropzone">
                    <Grid item>
                      <Box className="icon-drag-and-drop">
                        <ImageIcon classes="icon-drag-and-drop-blue"></ImageIcon>
                      </Box>
                    </Grid>
                    <Grid>
                      <Typography>{t("drag_and_drop_file")}</Typography>
                    </Grid>
                  </div>
                )}
              </div>
            </>
          )}
        </CSVReader>
      </Grid>
      <Grid
        item
        container
        className={`${
          isUploaded ? "information-text-uploded" : "information-text"
        }`}
      ></Grid>
      <Grid item className="target-submit-not-uploaded">
        <Box>
          <Button
            type="small"
            variant={`${isUploaded ? "primary" : ""}`}
            isDisabled={`${isUploaded ? "notDisabled" : "disabled"}`}
            onClick={onSubmit}
          >
            {userOperation === "Add"
              ? t("validate_added_HCP")
              : t("validate_removed_HCP")}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
