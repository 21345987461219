import "../TargetV2.scss";
import TargetV2Header from "./header/TargetV2Header";
import { Box, Grid, Tooltip } from "@mui/material";
import { DragDropContext } from "react-beautiful-dnd";
import { useTargetV2Context } from "../context/targetV2.context";
import DropDownFiltersContainer from "./dropdown/DropDownFiltersContainer";
import SearchFilter from "./searchFilter/SearchFilter";
import SubGroupFilterItemsContainer from "./filterItems/subGroupFilter/SubGroupFilterItemsContainer";
import TargetV2Footer from "./footer/TargetV2Footer";
import { useCallback, useEffect, useMemo, useState, FC, useRef } from "react";
import CustomFiltersContainer from "./customFilters/CustomFiltersContainer";
import { Button } from "components/shared/Button";
import { useTranslation } from "react-i18next";
import Preview from "./preview/Preview";
import { makeStyles } from "@mui/styles";
import { Popup } from "components/shared/Popup";
import { Dropdown } from "components/shared/Dropdown";
import { getUpdateSavedTargetsData } from "services/savedTargetService";
import { ImageIcon } from "components/shared/ImageIcon";
import { useHistory } from "react-router-dom";
import { routes } from "core/constants";
import _ from "lodash";
import { useRecoilValue } from "recoil";
import { userDetailsState } from "recoil/atoms";

const useStyles = makeStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    left: "34% !important",
    color: "white",
  },
  tooltip: {
    backgroundColor: "white",
    boxShadow: "0px 3px 6px #00000029",
    width: "42%",
    color: "#808486",
    fontSize: "12px",
    fontFamily: "Open Sans Regular",
    marginTop: "12px",
    padding: "8px",
    marginLeft: "35%",
    top: "7px",
  },
}));
type containerProps = {
  savedTargetID: any;
};
const TargetV2Container: FC<containerProps> = ({ savedTargetID }) => {
  const {
    onDragEnd,
    filtersDropdownData,
    targetListData,
    targetListPreviewText,
    isSearching,
    searchingFiltersResults,
    setTargetListPreviewText,
    handleGenerateTargetListPreviewText,
    setIsApplyingFilters,
    isApplyingFilters,
    handleFindMandatoryMissing,
    setIsMissingMandatoryFields,
    fetchCountOfVolumes,
    fetchFiltersListData,
    targetListId,
    targetListCsvPreviewUrl,
    targetListCsvProcessingUrl,
    targetListName,
    savedTargetInfo,
    varianceTL,
    userInteraction,
    showCustomMsg,
    setShowCustomMsg,
    groupExpanded,
  } = useTargetV2Context();

  let currentLocation = window?.location;
  let parentLocation = window?.parent?.location;

  const noIFrameLoaded =
    currentLocation && parentLocation && parentLocation === currentLocation
      ? true
      : false;
  const [isExpandCategoryFilter, setIsExpandCategoryFilter] = useState<
    number | null
  >();
  const [saveTarget, setSaveTarget] = useState(false);
  const [saveTargetSuccess, setSaveTargetSuccess] = useState(false);
  const [targetName, setTargetName] = useState("");
  const [targetType, setTargetType] = useState(null);
  const userDetails = useRecoilValue(userDetailsState);
  const [savedTargetListId, setSavedTargetListId] = useState<number | null>();
  const [saveAsNew, setSaveAsNew] = useState(false);
  let history: any = useHistory();
  const divRef = useRef<HTMLDivElement | null>(null);
  const targetTypeOptions = [
    { value: "global_segment", label: "Global segment" },
    { value: "local_segment", label: "Local segment" },
    { value: "campaign_target_list", label: "Campaign target list" },
  ];

  const tagetTypeConfig = {
    useFormContext: false,
    id: "target-type-dropdown",
    label: "target-type-dropdown",
    placeholder: "Select your type",
    options: targetTypeOptions,
  };

  const handleSaveTarget = () => {
    setSaveTarget(true);
  };

  const closeSaveTarget = () => {
    setSaveTarget(false);
    setTargetType(null);
    setSaveTargetSuccess(false);
    if (!savedTargetID) {
      setTargetName("");
    }
  };
  const updateTargetName = (event: any) => {
    if (event.target.value !== targetListName) {
      setSaveAsNew(true);
    } else {
      setSaveAsNew(false);
    }
    setTargetName(event.target.value);
  };

  const updateTargetType = (val: any) => {
    setTargetType(val.value);
  };

  let classes = useStyles();
  const { t } = useTranslation();

  const saveTargetName = (event: any) => {
    let action = event.currentTarget.dataset.action;
    let targetIdInfo: any =
      action === "save" ? savedTargetListId : targetListId;
    const data = {
      targetId: targetIdInfo,
      campaignName: targetName,
      targetType: targetType,
      targetData: targetListData[0],
      previewUrl: targetListCsvPreviewUrl,
      processingUrl: targetListCsvProcessingUrl,
    };
    getUpdateSavedTargetsData(data)?.then((response: any) => {
      if (response) {
        setSaveTargetSuccess(true);
      }
    });
    setSaveTargetSuccess(true);
  };

  const goToDashboard = () => {
    let path = routes.dashboard;
    history.push(path);
  };

  const moveToShipment = () => {
    const data = {
      targetId: targetListId,
      campaignName: targetName,
      targetType: targetType,
      targetData: targetListData[0],
      previewUrl: targetListCsvPreviewUrl,
      processingUrl: targetListCsvProcessingUrl,
    };

    getUpdateSavedTargetsData(data)?.then((response: any) => {
      if (response) {
        if (response.data && response.data.data) {
          let path = routes.shipmentWithFiltersId;
          path = path.replace(":id", response.data.data);
          history.push(path);
        }
      }
    });
  };

  useEffect(() => {
    const data = handleGenerateTargetListPreviewText(targetListData[0], 0);
    setTargetListPreviewText(`(${data.join(" ")})`);
  }, [
    handleGenerateTargetListPreviewText,
    targetListData,
    setTargetListPreviewText,
  ]);

  useEffect(() => {
    if (savedTargetID) {
      fetchFiltersListData(savedTargetID);
    } else {
      fetchFiltersListData();
    }
  }, [fetchFiltersListData, savedTargetID]);

  useEffect(() => {
    setTargetName(targetListName);
  }, [targetListName]);

  useEffect(() => {
    setSavedTargetListId(savedTargetInfo);
  }, [savedTargetInfo]);

  const closePopUp = () => {
    setShowCustomMsg(false);
  };
  const filtersData = useMemo(() => {
    return isSearching ? searchingFiltersResults : filtersDropdownData;
  }, [filtersDropdownData, isSearching, searchingFiltersResults]);

  const handleApplyFiltersClick = useCallback(() => {
    if (handleFindMandatoryMissing(targetListData)) {
      setIsMissingMandatoryFields(true);
      setIsApplyingFilters(false);
    } else {
      fetchCountOfVolumes(targetListData[0], null);
    }
  }, [
    setIsApplyingFilters,
    targetListData,
    handleFindMandatoryMissing,
    setIsMissingMandatoryFields,
    fetchCountOfVolumes,
  ]);

  const isFilterValueSelected = (data: any) => {
    const isEmptyfilterGroup = !!_.find(data, (item) =>
      _.some(
        item.filterItemsChildren,
        (filterItemsChildren) => !_.isEmpty(filterItemsChildren.filtersSelected)
      )
    );

    const isEmptyfilterGroupSubgroup = !!_.find(data, (item) =>
      _.some(
        item.subgGroupFilterItemsChildren,
        (subgGroupFilterItemsChildren) =>
          _.some(
            subgGroupFilterItemsChildren.filterItemsChildren,
            (filterItemsChildren) =>
              !_.isEmpty(filterItemsChildren.filtersSelected)
          )
      )
    );

    const res = isEmptyfilterGroupSubgroup
      ? true
      : isEmptyfilterGroup
      ? true
      : false;
    if (!res) {
      const isEmptyfilterGroupSubgroup = !!_.find(data, (item) =>
        _.some(
          item.subgGroupFilterItemsChildren,
          (subgGroupFilterItemsChildren) =>
            _.some(
              subgGroupFilterItemsChildren.subgGroupFilterItemsChildren,
              (subGrp) =>
                _.some(
                  subGrp.filterItemsChildren,
                  (filterItemsChildren) =>
                    !_.isEmpty(filterItemsChildren.filtersSelected)
                )
            )
        )
      );
      if (isEmptyfilterGroupSubgroup) {
        return isEmptyfilterGroupSubgroup;
      } else {
        const isEmptyfilterGroupInnerSubgroup = !!_.find(data, (item) =>
          _.some(
            item.subgGroupFilterItemsChildren,
            (subgGroupFilterItemsChildren) =>
              _.some(
                subgGroupFilterItemsChildren.subgGroupFilterItemsChildren,
                (subGrp) =>
                  _.some(subGrp.subgGroupFilterItemsChildren, (innerSubGrp) =>
                    _.some(
                      innerSubGrp.filterItemsChildren,
                      (filterItemsChildren) =>
                        !_.isEmpty(filterItemsChildren.filtersSelected)
                    )
                  )
              )
          )
        );

        return isEmptyfilterGroupInnerSubgroup;
      }
    }
    return res;
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box className="target-wrapper" position="relative">
        <TargetV2Header />
        <Grid display="flex">
          <Box
            borderRight="1px solid #e8e8e8"
            className={`targeting-left-area custom-scroll ${
              noIFrameLoaded ? "" : "iframe-area"
            }`}
          >
            <SearchFilter />

            {filtersData.map(
              (
                filterList: { filterItemsChildren: any; id: any; name: any },
                index: number
              ) =>
                filterList.filterItemsChildren.length > 0 && (
                  <DropDownFiltersContainer
                    filterList={filterList}
                    key={filterList.id}
                    lastEl={index === filtersData.length - 1}
                    setIsExpandCategoryFilter={setIsExpandCategoryFilter}
                    isExpandCategoryFilter={isExpandCategoryFilter}
                  />
                )
            )}
            {userDetails.role === "Super User" && (
              <CustomFiltersContainer
                targetListCsvProcessingUrl={targetListCsvProcessingUrl}
                targetListData={targetListData[0]}
              />
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            overflow="hidden"
            paddingRight="4px"
            position="relative"
            className={`targeting-right-area custom-scroll ${
              noIFrameLoaded ? "" : "iframe-area"
            }`}
            ref={divRef}
          >
            {isApplyingFilters &&
              showCustomMsg &&
              varianceTL &&
              userInteraction && (
                <Box className="info-custom" position="absolute">
                  <Box className="info">
                    {varianceTL} HCPs had been{" "}
                    {userInteraction === "Add" ? "added" : "removed"}
                  </Box>
                  <Box className="close-icon" onClick={closePopUp}>
                    <ImageIcon classes="icon-cross"></ImageIcon>
                  </Box>
                </Box>
              )}
            <Box className="summary-wrapper" position="relative">
              <Box className="summary-padding">
                <Box className="summary-title">{t("summary")}</Box>
                <Box
                  dangerouslySetInnerHTML={{ __html: targetListPreviewText }}
                  className="summary-desc"
                />
                {isApplyingFilters && (
                  <Box position="absolute" top="6px" right="18px">
                    <Box className="summary-count-hcps-wrapper">
                      <Box className="summary-count-number">
                        {t("target_list_contains")}{" "}
                        {targetListData[0].countHCPS} HCPS
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            {targetListData[0] && (
              <SubGroupFilterItemsContainer
                subGroupFilterItem={targetListData[0]}
                level={0}
                index={0}
              />
            )}
            {targetListData[0].subgGroupFilterItemsChildren.length > 0 && (
              <Tooltip
                placement="top-end"
                title={
                  isFilterValueSelected(
                    targetListData[0].subgGroupFilterItemsChildren
                  )
                    ? ""
                    : "Select atleast one filter value"
                }
                arrow
                classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                PopperProps={{ keepMounted: true }}
              >
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  padding="8px"
                  marginBottom="8px"
                >
                  <Button
                    type="small"
                    variant="secondary"
                    onClick={handleApplyFiltersClick}
                    isDisabled={
                      isFilterValueSelected(
                        targetListData[0].subgGroupFilterItemsChildren
                      )
                        ? "notDisabled"
                        : "disabled"
                    }
                  >
                    {t("apply_filters")}
                  </Button>
                </Box>
              </Tooltip>
            )}
          </Box>
        </Grid>
        {isApplyingFilters && <Preview hcps={targetListData[0].countHCPS} />}

        <TargetV2Footer
          isApplyingFilters={isApplyingFilters}
          handleSaveTarget={handleSaveTarget}
          userRole={userDetails.role}
          moveToShipment={moveToShipment}
        />

        <Popup
          open={saveTarget}
          handleClose={closeSaveTarget}
          title={
            !saveTargetSuccess
              ? t("choose_a_target_name")
              : t("target_list_has_been_saved")
          }
          maxWidth="md"
          class="save-target-popup"
        >
          {!saveTargetSuccess ? (
            <>
              <Grid
                container
                justifyContent="center"
                alignItems="flex-start"
                className="save-popup"
                direction="column"
              >
                <label className="target-name">{t("target_name")}</label>
                <input
                  type="text"
                  className="input-target-name"
                  value={targetName}
                  onChange={updateTargetName}
                />
                {savedTargetListId && targetListId !== savedTargetListId && (
                  <label className="target-replace-info">
                    {t("target-replace-info")}
                  </label>
                )}
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="flex-start"
                className="save-popup"
                direction="column"
              >
                <label className="target_type">{t("target_type")}</label>
                <Dropdown
                  config={tagetTypeConfig}
                  onChange={updateTargetType}
                ></Dropdown>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                direction="row"
                alignItems="center"
                className="btn-apply"
              >
                <Grid item>
                  <Button
                    variant="secondary"
                    type="small"
                    onClick={closeSaveTarget}
                  >
                    {t("cancel")}
                  </Button>
                </Grid>
                {savedTargetListId && targetListId !== savedTargetListId && (
                  <Grid item>
                    <Button
                      variant={saveAsNew ? "primary" : "secondary"}
                      type="small"
                      data-action="save_as"
                      isDisabled={saveAsNew ? "notDisabled" : "disabled"}
                      onClick={saveTargetName}
                    >
                      {t("save_as_new")}
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    variant="primary"
                    type="small"
                    data-action={
                      savedTargetListId && targetListId !== savedTargetListId
                        ? "save"
                        : "save_as"
                    }
                    isDisabled={
                      targetName &&
                      savedTargetListId &&
                      targetListId !== savedTargetListId &&
                      saveAsNew
                        ? "disabled"
                        : targetName &&
                          savedTargetListId &&
                          targetListId !== savedTargetListId &&
                          !saveAsNew
                        ? "notDisabled"
                        : targetName
                        ? "notDisabled"
                        : "disabled"
                    }
                    onClick={saveTargetName}
                  >
                    {t("save")}
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                className="save-success-popup"
                direction="column"
              >
                <Grid item className="target-success-icon">
                  <ImageIcon classes="icon-target-success"></ImageIcon>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  direction="row"
                  alignItems="center"
                >
                  <Grid item>
                    <Button
                      variant="secondary"
                      type="small"
                      onClick={goToDashboard}
                    >
                      {t("go_to_the_dashboard")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Popup>
      </Box>
    </DragDropContext>
  );
};

export default TargetV2Container;
