import { ClickAwayListener, Grid } from "@mui/material";
import { Button } from "components/shared/Button";
import { Toast } from "components/shared/Toast";
import { getMessage } from "helpers/homeHelper";
import { dateFormatConvertor } from "helpers/sharedHelper";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { userDetailsState } from "recoil/atoms";
import {
  getNotificationDetails,
  updateNotificationDetails,
} from "services/homeService";
import "./NotificationsList.scss";
import { format } from "date-fns-tz";

export function NotificationsList(props: any) {
  const [show, setShow] = useState<Boolean>(false);
  const [showAll, setShowAll] = useState<Boolean>(false);
  const [notificationsList, setNotificationList] = useState<any>(null);
  const userDetails = useRecoilValue(userDetailsState);
  useEffect(() => {
    let date = new Date();
    let dateNew = dateFormatConvertor(date, "dd-MM-yyyy");
    let email = userDetails.email;

    getNotificationDetails(dateNew, email)?.then((response: any) => {
      if (response && response.data && response.data.data) {
        let dataFomated = response.data.data.map((item: any) => {
          const actualDate = item.createdTime.replace(" ", "T") + "Z";
          let txtFor =
            item.isError || item.notificationType === "TARGETS_HOME_SFMC"
              ? ""
              : "";
          return {
            operationType: item.notificationType,
            message: getMessage(item.notificationType, item.isError) + txtFor,
            className: "notification-list-tost-container ",
            marginColor: item.isError
              ? "red-notification"
              : item.readStatus
              ? "read-notification"
              : "green-notification",
            contentColor: item.isError
              ? "red-notification"
              : item.readStatus
              ? "read-notification"
              : "green-notification",
            readStatus: item.readStatus,
            iconClass: item.isError ? "icon-errortick" : "icon-tick",
            showViewButton: true,
            data: {
              ...item,
              operationType: item.notificationType,
              entityId: item.eventId,
            },
            buttonText: item.uniqueId,
            messageClass: "message-class",
            time: item.createdTime,
            convertDate: format(new Date(actualDate), "do MMM yyyy"),
            convertTime: format(new Date(actualDate), "hh:mm a"),
          };
        });
        setNotificationList(dataFomated);
        setShow(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setShow(false);
    props.onclose && props.onclose();
  };

  const notificationItem = (item: any) => {
    const updateNotification = () => {
      updateNotificationDetails(item.data.id);
    };
    return (
      <Grid item xs={12} paddingBottom={"2px"} key={item.data.id}>
        <Toast
          className="notification-list-tost-container"
          {...item}
          onClick={updateNotification}
        />
      </Grid>
    );
  };

  const getNotifications = () => {
    return notificationsList?.map((item: any) => {
      return notificationItem(item);
    });
  };
  const viewAll = () => {
    setShowAll(true);
  };
  return (
    show && (
      <ClickAwayListener onClickAway={handleClose}>
        <Grid item container className="notifications-list-wrapper">
          <Grid
            item
            className={`notifications-list-container custom-scroll ${
              showAll ? "show-all-class" : ""
            }`}
          >
            {getNotifications()}
          </Grid>

          {!showAll && (
            <Grid item xs={12}>
              <Button
                variant="secondary"
                class="view-all-button-class"
                onClick={viewAll}
              >
                View All
              </Button>
            </Grid>
          )}
          {showAll && (
            <Grid item xs={12}>
              <Button
                variant="secondary"
                class="view-all-button-class"
                onClick={handleClose}
              >
                Close
              </Button>
            </Grid>
          )}
        </Grid>
      </ClickAwayListener>
    )
  );
}
