import { Box, Tooltip } from "@mui/material";
import React, { FC, useCallback } from "react";
import "./SelectFilter.scss";
import dropdown_arrow_black from "../../../../../../../src/assets/icons/icon-dropdown-arrow-black.svg";
import dropdown_arrow_blue from "../../../../../../../src/assets/icons/icon-arrow-down-blue.svg";
import "./SelectSimpleFilter.scss";
import { makeStyles } from "@mui/styles";

type SelectSimpleFilterProps = {
  handleExpandFilter: () => void;
  filtersSelected?: Array<string>;
  isExpandFilter: boolean;
  filter: string;
  borderColor?: string;
  width?: string;
  isQuestion?: boolean;
};

const useStyles = makeStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white",
  },
  tooltip: {
    backgroundColor: "white",
    boxShadow: "0px 3px 6px #00000029",
    width: "160px",
    color: "#808486",
    fontSize: "12px",
    fontFamily: "Open Sans Regular",
    padding: "8px",
  },
}));

const SelectSimpleFilter: FC<SelectSimpleFilterProps> = ({
  handleExpandFilter,
  filtersSelected,
  isExpandFilter,
  filter,
  borderColor,
  width,
  isQuestion,
}) => {
  let classes = useStyles();

  return (
    <Box
      className="simple-filter-select-wrapper"
      style={{
        borderColor: isExpandFilter
          ? "#2D9DE2"
          : borderColor
          ? borderColor
          : "",
        width: width ?? "",
      }}
      onClick={handleExpandFilter}
    >
      <Box className="select-filter-wrapper">
        <Tooltip
          title={
            filtersSelected &&
            filtersSelected?.length > 0 &&
            filter !== "Survey name"
              ? filtersSelected[0]
              : ""
          }
          placement="top"
          arrow
          classes={{
            arrow: classes.arrow,
            tooltip: classes.tooltip,
          }}
          PopperProps={{ keepMounted: true }}
        >
          <Box className="filter-selected-wrapper">
            <Box
              className={`simple-filter-selected-name ${
                filtersSelected &&
                filtersSelected?.length > 0 &&
                filter !== "Survey name"
                  ? ""
                  : ""
              }`}
            >
              {filtersSelected && filtersSelected?.length > 0 ? (
                filter === "Survey name" ? (
                  filtersSelected[0]
                ) : filtersSelected[0].length > 40 ? (
                  <>
                    {" "}
                    <span>{filtersSelected[0].slice(0, 50)}</span>
                    <span className="survey_txt_clr"> ...</span>{" "}
                  </>
                ) : (
                  filtersSelected[0]
                )
              ) : filter !== "Survey name" ? (
                "Select the answer"
              ) : (
                `Select the ${filter}`
              )}
            </Box>
          </Box>
        </Tooltip>
      </Box>
      <Box className="border-chevron-wrapper">
        <img
          src={dropdown_arrow_blue}
          alt="Gray icon"
          className="arrow-down"
          style={{ transform: isExpandFilter ? "rotate(180deg)" : "" }}
        />
      </Box>
    </Box>
  );
};

export default SelectSimpleFilter;
