import React from "react";
import { Box, Typography } from "@mui/material";
import { ImageIcon } from "components/shared/ImageIcon";
import { Button } from "../Button";

/**
 * Here props.type can a type of popup for e.g error or warning popup. Accordingly add class in scss
 */

export function Popup(props: any) {
  if (!props.open) return null;

  const openNewTab= () =>{
    window.open(
      props.customUrl,
      '_blank' 
    );
  }
  return (
    <section
      className={`overlay ${props.parentClass ? props.parentClass : ""}`}
    >
      <div
        className={`popup-wrapper custom-scroll 
        ${props.type ? props.type : ""} ${
          props.maxWidth ? props.maxWidth : "lg"
        } ${props.class ? props.class : ""}`}
      >
        {props.title ? (
          <div
            className={`popup-header ${
              !props.headerIcon ? "popup-header-no-icon" : ""
            } `}
          >
            {props.headerIcon ? (
              <Box
                className={`${
                  props.type === "error" ? "error-popup" : "img-logo-container"
                }`}
              >
                <ImageIcon classes={props.headerIcon}></ImageIcon>
              </Box>
            ) : null}
            <Box className="popup-title">
              <Typography>{props.title}</Typography>
            </Box>

            {props.customBtn && (
              <Box className="customBtn" marginLeft={"70%"}>
                <Button type="small" variant="secondary" onClick={openNewTab}>
                Open in new tab 
                </Button>
              </Box>
            )}
            <Box className="close-icon" onClick={props.handleClose}>
              <ImageIcon classes="icon-close"></ImageIcon>
            </Box>
          </div>
        ) : (
          <Box className="close" onClick={props.handleClose}>
            <ImageIcon classes="icon-close"></ImageIcon>
          </Box>
        )}
        <div className="popup-body">{props.children}</div>
      </div>
    </section>
  );
}
