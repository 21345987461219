import { Box, Tooltip } from "@mui/material";
import React, { FC } from "react";
import "./SelectFilter.scss";
import dropdown_arrow_blue from "../../../../../../../src/assets/icons/icon-arrow-down-blue.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import { makeStyles } from "@mui/styles";

type SelectFilterProps = {
  handleExpandFilter: () => void;
  filtersSelected?: Array<string>;
  isExpandFilter: boolean;
  isMandatory?: boolean;
  handleUpdateFilterClick: (filter: string) => void;
};

const useStyles = makeStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white",
  },
  tooltip: {
    backgroundColor: "white",
    boxShadow: "0px 3px 6px #00000029",
    width: "160px",
    color: "#808486",
    fontSize: "12px",
    fontFamily: "Open Sans Regular",
    padding: "8px",
  },
}));

const SelectFilter: FC<SelectFilterProps> = ({
  handleExpandFilter,
  filtersSelected,
  isExpandFilter,
  isMandatory,
  handleUpdateFilterClick,
}) => {
  let classes = useStyles();
  return (
    <Box
      className={`select-filter-container ${
        filtersSelected && filtersSelected[0]?.length > 30 ? "with-gap" : ""
      }`}
      style={{
        borderColor: isExpandFilter ? "#2D9DE2" : "",
        cursor: "pointer",
      }}
      onClick={handleExpandFilter}
    >
      <Tooltip
        title={
          filtersSelected && filtersSelected[0]?.length > 27
            ? filtersSelected
            : ""
        }
        placement="top"
        arrow
        classes={{
          arrow: classes.arrow,
          tooltip: classes.tooltip,
        }}
        PopperProps={{ keepMounted: true }}
      >
        <Box
          className={`select-filter-wrapper ${
            filtersSelected && filtersSelected[0]?.length > 30
              ? "select-filter-wrapper_with_gap"
              : ""
          }`}
        >
          {filtersSelected?.map((filterSelected, i) => (
            <Box key={i}>
              <Box
                className={`filter-selected-wrapper ${
                  filtersSelected && filtersSelected[0]?.length > 30
                    ? "filter-selected-wrapper_with_gap"
                    : ""
                }`}
              >
                <Box
                  className={`filter-selected-name ${
                    filtersSelected && filtersSelected[0]?.length > 30
                      ? "ellipse-container"
                      : ""
                  }`}
                >
                  {filtersSelected && filtersSelected[0]?.length > 35 ? (
                    <>
                      {" "}
                      <span>{filtersSelected[0].slice(0, 35).trim()}</span>
                      <span className="survey_txt_clr"> ...</span>{" "}
                    </>
                  ) : (
                    filterSelected
                  )}
                </Box>

                {!isMandatory && (
                  <CancelIcon
                    onClick={() => handleUpdateFilterClick(filterSelected)}
                    cursor="pointer"
                    style={{
                      width: "18px",
                      color: "#a2abb1",
                      paddingRight: "4px",
                    }}
                  />
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Tooltip>
      <Box className="border-chevron-wrapper">
        <img
          src={dropdown_arrow_blue}
          alt="Gray icon"
          className="arrow-down"
          style={{ transform: isExpandFilter ? "rotate(180deg)" : "" }}
        />
      </Box>
    </Box>
  );
};

export default SelectFilter;
