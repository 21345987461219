import { Box } from "@mui/material";
import React, { FC, useRef } from "react";
import "./SubGroupNotExpanded.scss";
import { SubGroupFilterItem } from "pages/TargetV2/types/targetV2.types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DropDownSelect from "../components/dropdownSelect/DropDownSelect";

type SubGroupNotExpandedProps = {
  subgGroupFilterItem: SubGroupFilterItem;
  index: number;
  handleHeaderMenuOfGroupClick: (
    value: string,
    index?: number,
    indexOfValue?: number
  ) => void;
  expandedDropDownHeaderMenu: number | null | undefined;
  setExpandedDropDownHeaderMenu: (
    value: React.SetStateAction<number | null | undefined>
  ) => void;
};
const SubGroupNotExpanded: FC<SubGroupNotExpandedProps> = ({
  subgGroupFilterItem,
  index,
  handleHeaderMenuOfGroupClick,
  expandedDropDownHeaderMenu,
  setExpandedDropDownHeaderMenu,
}) => {
  const { setGroupExpanded, isApplyingFilters, targetListData } =
    useTargetV2Context();

  const refHeaderMenuNotExpanded = useRef<HTMLDivElement>(null);

  return (
    <Box
      className={`subgroup-not-expanded-container ${
        index !== 0 ? "no-border" : ""
      } `}
      ref={refHeaderMenuNotExpanded}
      onClick={() => setGroupExpanded(subgGroupFilterItem.subGroupId)}
    >
      <Box className="group-not-expanded-name">
        {subgGroupFilterItem.name} {index + 1}
      </Box>

      <Box display="flex" paddingRight="20px" position="relative">
        <ExpandMoreIcon
          style={{
            cursor: "pointer",
            width: "24px",
            paddingRight: "8px",
          }}
        />

        <MoreVertIcon
          style={{
            border: "1px solid #637e88",
            borderRadius: "50%",
            color: "#637e88",
            width: "24px",
            cursor: "pointer",
          }}
          onClick={(e: any) => {
            e.stopPropagation();
            setExpandedDropDownHeaderMenu(
              subgGroupFilterItem.subGroupId === expandedDropDownHeaderMenu
                ? null
                : subgGroupFilterItem.subGroupId
            );
          }}
        />
        <DropDownSelect
          values={["Delete the group", "Replicate the group"]}
          isExpandFilter={
            subgGroupFilterItem.subGroupId === expandedDropDownHeaderMenu
          }
          handleClick={handleHeaderMenuOfGroupClick}
          index={index}
          style={{ right: "0px", width: "200px", top: "30px" }}
        />
      </Box>
      {isApplyingFilters && subgGroupFilterItem.countHCPS !== null && (
        <Box
          style={{
            position: "absolute",
            right: -108,
            bottom:
              index !==
              targetListData[0].subgGroupFilterItemsChildren.length - 1
                ? 15
                : 85,
          }}
        >
          <Box className="count-hcps-wrapper">
            <Box className="count-hcps-number">
              {subgGroupFilterItem.countHCPS} HCPS
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SubGroupNotExpanded;
