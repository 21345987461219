import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "./CustomToggle.scss";

import { useRecoilState } from "recoil"; // Import Recoil hooks
import { alignmentState } from "../../../recoil/atoms";
import { useHistory } from "react-router-dom";
import { routes } from "core/constants";

export function CustomToggle() {
  let history = useHistory();
  const [alignment, setAlignment] = useRecoilState(alignmentState);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    if (newAlignment && newAlignment !== alignment) {
      setAlignment(newAlignment);
    }
  };

  const navigateTohome = () => {
    let path = routes.home;
    history.push(path);
  };

  return (
    <div className="custom-toggle-group">
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        <ToggleButton
          onClick={navigateTohome}
          className={`custom-toggle-btn ${
            alignment === "apps" ? "selected" : ""
          }`}
          value="apps"
        >
          Apps
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
