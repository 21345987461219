import React, { FC, useCallback, useState } from "react";
import { Box } from "@mui/material";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";
import { FilterItem } from "pages/TargetV2/types/targetV2.types";
import DateRangeTarget from "./DateRangeTarget";
import SelectSimpleDate from "./SelectSimpleDate";
import { Range } from "react-date-range";
import { useTranslation } from "react-i18next";

type SimpleDateRangeProps = {
  filterItem: FilterItem;
  id: number;
  index: number;
  groupType?: string;
  onChange?: any;
};

const SimpleDateRange: FC<SimpleDateRangeProps> = ({
  filterItem,
  id,
  index,
  groupType,
  onChange,
}) => {
  const [isExpandDateRange, setIsExpandDateRange] = useState<boolean>(false);
  const { handleUpdateDateRange } = useTargetV2Context();
  const { t } = useTranslation();
  const handleExpandDateRange = useCallback(() => {
    setIsExpandDateRange((state) => !state);
  }, []);

  const handleUpdateDateRangeClick = useCallback(
    (value: Range) => {
      if (onChange) {
        onChange();
      }
      const { startDate, endDate } = value;
      if (startDate && endDate) {
        handleUpdateDateRange(
          id,
          0,
          startDate,
          endDate,
          filterItem.isResponsive,
          groupType
        );
      }
    },
    [handleUpdateDateRange, id, filterItem, groupType, onChange]
  );

  return (
    <Box className="simple-filter-row-container">
      {filterItem && filterItem.isRecommended && (
        <Box className="mandatory recommended"> {t("recommended")} </Box>
      )}
      <Box className="simple-filter-list-wrapper">
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          marginTop="22px"
          position="relative"
        >
          <Box className="simple-filter-name" style={{ minWidth: "120px" }}>
            Date range
          </Box>
          <SelectSimpleDate
            handleExpandDateRange={handleExpandDateRange}
            startDate={filterItem.startDateSelected}
            endDate={filterItem.endDateSelected}
            isExpandDateRange={isExpandDateRange}
          />
          {isExpandDateRange && (
            <DateRangeTarget
              handleUpdateDate={handleUpdateDateRangeClick}
              setIsExpandDateRange={setIsExpandDateRange}
              startDate={filterItem.startDateSelected}
              endDate={filterItem.endDateSelected}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SimpleDateRange;
