import {
  GroupType,
  OperatorType,
  SubGroupFilterItem,
  // FilterItem,
} from "../types/targetV2.types";

export const generateSubGroupFilter = (
  groupFilter: SubGroupFilterItem,
  generateUniqueId: number,
  isEmptyTargetList: boolean,
  destinationId: number
) => {
  const parentId = isEmptyTargetList
    ? Math.floor(generateUniqueId / 3)
    : destinationId;
  switch (groupFilter.groupType) {
    case GroupType.PAST_INTERACTION:
      const newPastInteractionGroup = generatePastInteractionGroup(
        groupFilter,
        generateUniqueId,
        parentId
      );
      return newPastInteractionGroup;

    case GroupType.CLASSIC:
      const newClassicGroup = generateClassicGroup(
        groupFilter,
        generateUniqueId,
        parentId
      );
      return newClassicGroup;

    case GroupType.MCCP:
      const newMCCPGroup = generateMCCPGroup(
        groupFilter,
        generateUniqueId,
        parentId
      );
      return newMCCPGroup;

    case GroupType.SURVEY:
      const newSurveyGroup = generateSurveyGroup(
        groupFilter,
        generateUniqueId,
        parentId
      );
      return newSurveyGroup;

    default:
      return { ...groupFilter };
  }
};

export const generateFirstGroupOfTargetList = (
  generateUniqueId: number,
  parentIdOfNewGroup: number
  // mandatoryFilteritem: FilterItem
) => {
  const newFirstGroupCreated: SubGroupFilterItem = {
    id: Math.floor(generateUniqueId / 3),
    subGroupId: Math.floor(generateUniqueId / 3),
    name: "Group",
    operator: OperatorType.AND,
    parentId: parentIdOfNewGroup,
    filterItemsChildren: [],
    groupType: GroupType.CLASSIC,
    subgGroupFilterItemsChildren: [],
  };
  return newFirstGroupCreated;
};

export const generatePastInteractionGroup = (
  groupFilter: SubGroupFilterItem,
  generateUniqueId: number,
  parentIdOfNewGroup: number
) => {
  const pastInteractionSubGroup: SubGroupFilterItem[] = [
    {
      ...groupFilter.subgGroupFilterItemsChildren[0],
      parentId: generateUniqueId,
      subGroupId: Math.floor(generateUniqueId / 2),
    },
  ];

  const newPastInteractionGroup: SubGroupFilterItem = {
    ...groupFilter,
    subGroupId: generateUniqueId,
    parentId: parentIdOfNewGroup,
    filterItemsChildren: groupFilter.filterItemsChildren.map((filters, i) => ({
      ...filters,
      filterId: generateUniqueId + (i + 1),
    })),
    subgGroupFilterItemsChildren: [...pastInteractionSubGroup],
  };

  return newPastInteractionGroup;
};

export const generateClassicGroup = (
  groupFilter: SubGroupFilterItem,
  generateUniqueId: number,
  parentIdOfNewGroup: number
) => {
  const newClassicGroup: SubGroupFilterItem = {
    ...groupFilter,
    subGroupId: generateUniqueId,
    parentId: parentIdOfNewGroup,
    filterItemsChildren: groupFilter.filterItemsChildren.map((filters, i) => ({
      ...filters,
      filterId: generateUniqueId + (i + 1),
    })),
    subgGroupFilterItemsChildren: [],
  };

  return newClassicGroup;
};

export const generateMCCPGroup = (
  groupFilter: SubGroupFilterItem,
  generateUniqueId: number,
  parentIdOfNewGroup: number
) => {
  const newMCCPGroup: SubGroupFilterItem = {
    ...groupFilter,
    subGroupId: generateUniqueId,
    parentId: parentIdOfNewGroup,
    filterItemsChildren: groupFilter.filterItemsChildren.map((filters, i) => ({
      ...filters,
      filterId: generateUniqueId + (i + 1),
    })),
    subgGroupFilterItemsChildren: [],
  };

  return newMCCPGroup;
};

export const generateSurveyGroup = (
  groupFilter: SubGroupFilterItem,
  generateUniqueId: number,
  parentIdOfNewGroup: number
) => {
  const newSurveyGroup: SubGroupFilterItem = {
    ...groupFilter,
    subGroupId: generateUniqueId,
    parentId: parentIdOfNewGroup,
    filterItemsChildren: groupFilter.filterItemsChildren.map((filters, i) => ({
      ...filters,
      filterId: generateUniqueId + (i + 1),
    })),
    subgGroupFilterItemsChildren: [],
  };

  return newSurveyGroup;
};
