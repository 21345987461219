import React, { FC } from "react";
import "./Filter.scss";
import { Box } from "@mui/material";

type FilterProps = {
  name: string;
  color: string;
  bgColor: string;
  icon?: React.ReactNode;
  isPointer?: boolean;
};
const Filter: FC<FilterProps> = ({ name, color, bgColor, icon, isPointer }) => {
  return (
    <Box className="filter-wrapper" bgcolor={bgColor}>
      <Box
        color={color}
        style={{
          fontFamily: icon ? "Open Sans semibold" : "Open Sans Regular",
          cursor: isPointer ? "pointer" : "",
        }}
        paddingLeft="30px"
      >
        {name}
      </Box>
      {icon && <Box paddingRight="24px">{icon} </Box>}
    </Box>
  );
};

export default Filter;
