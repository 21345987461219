import { MenuItem } from "models/menu";

/**
 * All the menu items are configured here
 * @returns array
 */
const getMenu = (): MenuItem[] => {
 
  return [
    {
      id: 1,
      label: "dashboard",
      path: "dashboard",
      inUse: true,
      iconClass: "icon-menu-campaign",
      titleLabel: "dashboard",
    },
    {
      id: 2,
      label: "target_list_generator_menu_label",
      path: "targeting",
      inUse: true,
      iconClass: "icon-menu-campaign",
      titleLabel: "target_list_generator_menu_label",
    }
  ];
};

export { getMenu };
