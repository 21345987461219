import { Box, Grid } from "@mui/material";
import {
  SubGroupFilterItem,
  OperatorType,
  FilterItem,
} from "pages/TargetV2/types/targetV2.types";
import React, { FC, useState, useCallback } from "react";
import "../pastInteraction/PastInteractionSubGroupContainer.scss";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";
import "../SubGroupFilterCustom.scss";
import OperatorLine from "../../components/operatorLine/OperatorLine";
import SubGroupFilterItemsContainer from "../../SubGroupFilterItemsContainer";
import arrow_up_blue from "../../../../../../../assets/icons/icon-arrow-up-blue-active.svg";
import { useTranslation } from "react-i18next";
import SelectOperatorDropDown from "../../components/selectOperatorDropDown/SelectOperatorDropDown";
import SurveyFilterNewRow from "../../filterRow/survey/SurveyFilterNewRow";
import { Popup } from "components/shared/Popup";
import { Button } from "components/shared/Button";
import "./SurveyNameContainer.scss";

type SurveyNameContainerProps = {
  subGroupFilterItem: SubGroupFilterItem;
  subGroup: SubGroupFilterItem;
  indexVal: number;
  level: number;
  snapchot: any;
};

const SurveyNameContainer: FC<SurveyNameContainerProps> = ({
  subGroupFilterItem,
  subGroup,
  indexVal,
  level,
  snapchot,
}) => {
  const { t } = useTranslation();
  const {
    handleDeleteSubGroupFilterItem,
    isApplyingFilters,
    arrayOfOperators,
    handleUpdateOperatorBetweenFiltersSelected,
  } = useTargetV2Context();
  const [openAccordion, setOpenAccordion] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);

  const closeConfirmPopup = () => {
    setOpenPopup(false);
  };

  const [expandedOperatorForAnswers, setExpandedOperatorForAnswers] = useState<
    number | null
  >(null);
  const toggleAccordion = () => {
    setOpenAccordion(!openAccordion);
  };

  const [isExpandFilterOfOperator, setIsExpandFilterOfOperator] =
    useState<boolean>(false);
  const handleUpdateOperatorForAnswersClick = useCallback(
    (operator: string, i?: number) => {
      const operatorToAdd: OperatorType = arrayOfOperators.filter(
        (e) => e === operator
      )[0];
      if (i !== undefined) {
        handleUpdateOperatorBetweenFiltersSelected(
          subGroupFilterItem.subgGroupFilterItemsChildren[0]
            .subgGroupFilterItemsChildren[i].subGroupId,
          i,
          operatorToAdd
        );
        setExpandedOperatorForAnswers(null);
      }
    },
    [
      handleUpdateOperatorBetweenFiltersSelected,
      subGroupFilterItem,
      arrayOfOperators,
    ]
  );

  const multiQuestionStyled = useCallback((filterItem: FilterItem) => {
    if (filterItem.multiSelect) {
      return {
        paddingBottom: "32px",
        border: "1px solid #e6e6e6",
        borderRadius: "8px",
        padding: "12px",
        gap: "34px",
      };
    }
  }, []);

  const handleDeleteSurveySubGroupFilterItem = useCallback(
    (id: number | null, index: number, name: string) => {
      const surveyGrpArray =
        subGroupFilterItem.subgGroupFilterItemsChildren.filter(
          (item) => item.groupType === "SURVEY_FILTERS"
        );

      if (
        name === "Survey name" &&
        index === 0 &&
        surveyGrpArray[0].subgGroupFilterItemsChildren.length === 1
      ) {
        setOpenPopup(true);
      } else {
        handleDeleteSubGroupFilterItem(subGroup.parentId, indexVal);
      }
    },
    [subGroup, indexVal, handleDeleteSubGroupFilterItem, subGroupFilterItem]
  );

  const handleDeleteSurveyDelete = () => {
    handleDeleteSubGroupFilterItem(subGroupFilterItem.parentId, 0);
    setOpenPopup(true);
  };

  return (
    <>
      <Box
        key={indexVal}
        className="first-subgroup-container-survey"
        position="relative"
      >
        <Box
          display="flex"
          flexWrap="wrap"
          flexDirection="column"
          justifyContent="space-between"
          maxWidth="100%"
          key={indexVal}
        >
          {subGroup.filterItemsChildren.length +
            subGroup.subgGroupFilterItemsChildren.length <
            2 && (
            <Box className="disable-help-text">
              Add more filters to enable the operator
            </Box>
          )}
          {/* <span>Each survey group including name and question</span> */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom="22px"
            key={indexVal}
          >
            <Box display="flex" alignItems="center" gap="22px">
              <Box className="operator-select">{t("select_your_operator")}</Box>
              <SelectOperatorDropDown
                operator={subGroup.operator}
                values={arrayOfOperators}
                index={indexVal}
                handleUpdateOperatorClick={handleUpdateOperatorForAnswersClick}
                expandedFilterOfOperator={
                  expandedOperatorForAnswers === subGroup.id
                }
                setExpandedFilterOfOperator={() => {
                  if (expandedOperatorForAnswers === subGroup.id) {
                    setExpandedOperatorForAnswers(null);
                  } else {
                    setExpandedOperatorForAnswers(subGroup.id);
                  }
                }}
                disable={
                  subGroup.filterItemsChildren.length +
                    subGroup.subgGroupFilterItemsChildren.length <
                  2
                    ? true
                    : false
                }
              />
            </Box>

            <Box gap="8px" display="flex" alignItems="center">
              <img
                src={arrow_up_blue}
                alt="arrow up icon"
                style={{
                  cursor: "pointer",
                  rotate: openAccordion ? "0deg" : "180deg",
                }}
                onClick={toggleAccordion}
              />
              <CancelIcon
                onClick={() =>
                  handleDeleteSurveySubGroupFilterItem(
                    subGroup.parentId,
                    indexVal,
                    subGroup.filterItemsChildren[0].name
                  )
                }
                cursor="pointer"
                className="cancel-icon"
              />
            </Box>
          </Box>
          {/* <span>endof >Each survey group including name and question</span> */}
          {/* accordion collapse ui> */}

          {!openAccordion && subGroup.filterItemsChildren.length > 0 && (
            <Box key={subGroup.filterItemsChildren[0].id}>
              <Box
                position="relative"
                style={{
                  ...multiQuestionStyled(subGroup.filterItemsChildren[0]),
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <SurveyFilterNewRow
                  key={subGroup.filterItemsChildren[0].filterId}
                  filterItem={subGroup.filterItemsChildren[0]}
                  width="50%"
                  style={{
                    justifyContent: "space-between",
                    marginLeft: "12px",
                    gap: "7px",
                  }}
                  isQuestion={true}
                  index={0}
                  name={subGroup.filterItemsChildren[0].name}
                  filters={subGroup.filterItemsChildren[0].filters}
                  filtersSelected={
                    subGroup.filterItemsChildren[0].filtersSelected
                  }
                  id={subGroup.subGroupId}
                  level={level}
                  isMandatory={subGroup.filterItemsChildren[0].isMandatory}
                  snapchot={snapchot}
                  isResponsive={subGroup.filterItemsChildren[0].isResponsive}
                  parentItem={subGroupFilterItem}
                />
              </Box>
              {!openAccordion &&
                isApplyingFilters &&
                subGroup.filterItemsChildren[0].countHCPS !== null && (
                  <Box
                    style={{
                      position: "absolute",
                      right: -108 - (level - 1) * 52,
                      top: 50,
                    }}
                  >
                    <Box className="count-hcps-wrapper">
                      <Box className="count-hcps-number">
                        {subGroup.filterItemsChildren[0].countHCPS} HCPS
                      </Box>
                    </Box>
                  </Box>
                )}
            </Box>
          )}
          {/* end of accordion collapse ui> */}
          {openAccordion &&
            subGroup.filterItemsChildren.length > 0 &&
            subGroup.filterItemsChildren.map((filterItem, index) => (
              <Box
                key={index}
              >
                <Box
                  position="relative"
                  style={{
                    ...multiQuestionStyled(filterItem),
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <SurveyFilterNewRow
                    key={filterItem.filterId}
                    filterItem={filterItem}
                    width="50%"
                    style={{
                      justifyContent: "space-between",
                      marginLeft: "12px",
                      gap: "7px",
                    }}
                    isQuestion={true}
                    index={index}
                    name={filterItem.name}
                    filters={filterItem.filters}
                    filtersSelected={filterItem.filtersSelected}
                    id={subGroup.subGroupId}
                    level={level}
                    isMandatory={filterItem.isMandatory}
                    snapchot={snapchot}
                    isResponsive={filterItem.isResponsive}
                    parentItem={subGroupFilterItem}
                  />
                </Box>
                {isApplyingFilters && filterItem.countHCPS !== null && (
                  <Box
                    style={{
                      position: "absolute",
                     right: -108 - (level - 1) * 52,
                     marginTop:-55,
                    }}
                  >
                    <Box className="count-hcps-wrapper">
                      <Box className="count-hcps-number">
                        {filterItem.countHCPS} HCPS
                      </Box>
                    </Box>
                  </Box>
                )}
                {index < subGroup.filterItemsChildren.length - 1 && (
                  <Box marginTop="24px" marginBottom="24px">
                    <OperatorLine operator={subGroup.operator} width="100%" />
                  </Box>
                )}
              </Box>
            ))}
          {openAccordion &&
            subGroup.subgGroupFilterItemsChildren.length > 0 && (
              <>
                <Box marginLeft="34px">
                  <OperatorLine operator={subGroup.operator} width="84%" />
                </Box>
                <div style={{ marginTop: "16px" }}>
                  {subGroup.subgGroupFilterItemsChildren.map(
                    (subGroupFilterItemChildren, index) => (
                      <>
                        {index > 0 && (
                          <Box marginLeft="34px">
                            <OperatorLine
                              operator={subGroup.operator}
                              width="84%"
                            />
                          </Box>
                        )}
                        <div key={index} style={{ position: "relative" }}>
                          <SubGroupFilterItemsContainer
                            subGroupFilterItem={subGroupFilterItemChildren}
                            level={level + 1}
                            index={index}
                            groupType={"SURVEY"}
                          />
                        </div>
                      </>
                    )
                  )}
                </div>
              </>
            )}
        </Box>
      </Box>

      <Popup
        open={openPopup}
        title={t("delete_survey")}
        handleClose={closeConfirmPopup}
        class="del-confrmtn-popup"
      >
        <>
          <Grid item>
            <div className="popup-confirm-msg">
              {t("are_you_sure_to_dele_survey")}
            </div>
            <div className="popup-content">
              {t("once_dele_no_longer_see_the_survey")}
            </div>
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            direction="row"
            alignItems="center"
            paddingTop={"8px"}
          >
            <Grid item>
              <Button
                variant="secondary"
                type="small"
                onClick={closeConfirmPopup}
              >
                {t("cancel")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="primary"
                type="small"
                onClick={handleDeleteSurveyDelete}
              >
                {t("delete_survey")}
              </Button>
            </Grid>
          </Grid>
        </>
      </Popup>
    </>
  );
};

export default SurveyNameContainer;
