import {
  GroupType,
  SubGroupFilterItem,
} from "pages/TargetV2/types/targetV2.types";
import React, { FC } from "react";
import PastInteractionSubGroupContainer from "./pastInteraction/PastInteractionSubGroupContainer";
import SurveyNewContainer from "./survey/SurveyNewContainer";
import { Box } from "@mui/material";

type SubGroupFilterCustomContainerProps = {
  parentFilterItem: SubGroupFilterItem;
  subGroupFilterItem: SubGroupFilterItem;
  level: number;
  index: number;
  groupType: GroupType;
  snapchot: any;
};

const SubGroupFilterCustomContainer: FC<SubGroupFilterCustomContainerProps> = ({
  parentFilterItem,
  subGroupFilterItem,
  level,
  index,
  groupType,
  snapchot,
}) => {
  return (
    <Box marginRight="22px" marginLeft="12px" marginBottom="22px" key={index}>
      {groupType === GroupType.PAST_INTERACTION ? (
        <PastInteractionSubGroupContainer
          subGroupFilterItem={subGroupFilterItem}
          index={index}
        />
      ) : groupType === GroupType.SURVEY ? (
        <SurveyNewContainer
          parentFilterItem={parentFilterItem}
          subGroupFilterItem={subGroupFilterItem}
          index={index}
          level={level}
          snapchot={snapchot}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default SubGroupFilterCustomContainer;
