import React, { useEffect } from "react";
import ErrorBoundary from "core/ErrorBoundary";
import { Layout } from "components/core/Layout";
import { Loader } from "components/core/Loader";
import "./App.scss";
import { ErrorPopup } from "components/ErrorPopup";
import { getNotificationDetails, getUserDetails } from "./services/homeService";
import { setSessionItem } from "./core/utils/helper";
import { storageKeys } from "./core/constants";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  userDetailsState,
  notificationState,
  userCountryDetailsState,
  messageQueueResponseState,
  pushMsgCountState,
} from "recoil/atoms";
import { getMessage } from "helpers/homeHelper";
import { useHistory } from "react-router-dom";
import { io } from "socket.io-client";
import { dateFormatConvertor } from "helpers/sharedHelper";

const theme = createTheme();

function App() {
  let history = useHistory();
  const setUserDetails = useSetRecoilState(userDetailsState);
  const setUserCountryDetails = useSetRecoilState(userCountryDetailsState);
  const setNotificationConfig = useSetRecoilState(notificationState);
  const [userDetailsLoaded, setUserDetailsLoaded] = React.useState(false);
  const setMessageQueueResponse = useSetRecoilState(messageQueueResponseState);
  const setPushMsgCount = useSetRecoilState(pushMsgCountState);

  let pushMsgCount = useRecoilValue(pushMsgCountState);

  const createNotification = (responseMsg: any) => {
    let msg = responseMsg ? JSON.parse(responseMsg) : null;

    let msgData = {
      ...responseMsg,
      operationType: msg.notificationType,
      entityId: msg.eventId,
    };
    setMessageQueueResponse(msgData);
    let txtFor =
      msg.isError ||
      msg.notificationType === "TARGETS_HOME_SFMC" ||
      msg.notificationType === "TARGETS_SHIPMENT_DETAILS_SFMC"
        ? ""
        : "";
    setNotificationConfig({
      duration:
        msg.notificationType === "TARGETS_SHIPMENT_DETAILS_LOCAL" ? 5000 : 3000,
      className: "notification",
      message: getMessage(msg.notificationType, msg.isError) + txtFor,
      marginColor: msg.isError ? "red-notification" : "green-notification",
      contentColor: msg.isError ? "red-notification" : "green-notification",
      iconClass: msg.isError ? "icon-errortick" : "icon-tick",
      data: {
        ...msg,
        operationType: msg.notificationType,
        entityId: msg.eventId,
      },
      showViewButton: true,
      buttonText: msg.uniqueId,
      operationType: msg.notificationType,
      showDownload:
        msg.notificationType === "TARGETS_SHIPMENT_DETAILS_LOCAL"
          ? true
          : false,
    });
  };

  const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionAttempts: 5,
  });

  useEffect(() => {
    getUserDetails()?.then((response) => {
      let userCountryData = [];
      if (response && response.data) {
        console.log("get user detailst");

        let email = response.data.data.email;
        socket.on("connect", () => {
          console.log("inital connect");
          socket.emit("connected", email);
          socket.on("notification", (responseMsg) => {
            console.log("notification msg received");
            setPushMsgCount(pushMsgCount + 1);
            setSessionItem(storageKeys.ASYNC_CALL_SET, "received");
            createNotification(responseMsg);
          });
        });
        socket.on("reconnect", () => {
          console.log("reconnecttt");
        });
        socket.on("disconnect", (reason) => {
          console.log("disconnect reason", reason);
          socket.connect();
        });

        let date = new Date();
        let dateNew = dateFormatConvertor(date, "dd-MM-yyyy");

        getNotificationDetails(dateNew, email)?.then((response: any) => {
          if (response && response.data && response.data.data) {
            setPushMsgCount(response.data.data.length);
          }
        });

        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.countryLst
        ) {
          userCountryData = response.data.data.countryLst;
        }
        let countryList = [];
        if (userCountryData.length > 0) {
          countryList = userCountryData;
        }
        setUserCountryDetails(countryList);
        setUserDetails(response.data.data);
        setUserDetailsLoaded(true);
        setSessionItem(storageKeys.USER_MAIL, response.data.data.email);
      } else {
        console.log("No user data available");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      history.replace({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        {userDetailsLoaded && <Layout />}
        <Loader />
        <ErrorPopup />
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
